import React from 'react'
import NavigationPrompt from 'react-router-navigation-prompt'
import { useTranslation } from 'react-i18next'
import PopError from '../common/ErrorHandler'

const NavigationPromptModal = (props) => {
  const { t } = useTranslation()
  const { when, prompt } = props

  return (
    <NavigationPrompt when={when}>
      {({ onConfirm, onCancel }) => {
        if (when && prompt) {
          // Old code prompt(callback);
          // add logic to call onConfirm and onCancel in the custom prompt
          return prompt({ onConfirm, onCancel })
        }
        return (
          <PopError
            errors={[
              {
                type: 'warning',
                title: t('Modal.Unsaved.Title'),
                message: t('Modal.Unsaved.Text'),
                confirmBtnText: t('Modal.Unsaved.Leave'),
                cancelBtnText: t('Modal.Unsaved.Cancel'),
                onConfirm: () => onConfirm(),
                onCancel: () => onCancel(),
                showCancel: true
              }
            ]}
          />
        )
      }}
    </NavigationPrompt>
  )
}

export default NavigationPromptModal
