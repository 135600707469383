import React from 'react';
import DocumentUrl from '../common/DocumentUrl';
import { useTranslation } from 'react-i18next';
import styles from './EmailDetail.scss';

const EmailDetail = (props) => {
  const { email } = props;
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <p>
          <b>{t('draftEmail.fromAddress')} :</b> {email.fromAddress}
        </p>
      </div>
      <div>
        <p>
          <b>{t('draftEmail.toAddresses')} :</b> {email.toAddresses}
        </p>
      </div>
      <div>
        <p>
          <b>{t('draftEmail.ccAddresses')}</b> {email.ccAddresses}
        </p>
      </div>
      <div>
        <p>
          <b>{t('draftEmail.subject')}</b> {email.subject}
        </p>
      </div>
      <hr />
      <div className={styles.emailcontent}>
        <div
          contentEditable='false'
          dangerouslySetInnerHTML={{ __html: email.message }}
        />
      </div>
      <hr />
      {email.documents && email.documents.length ? (
        <div>
          {email.documents.map((document, index) => (
            <div key={index}>
              <DocumentUrl
                getDocumentUrl={props.getDocumentUrl}
                document={props.document}
                file={document}
                styles='btn btn-default btn-sm'
              />
            </div>
          ))}
          <hr />
        </div>
      ) : null}

      {props.children ? props.children : null}
    </div>
  );
};

export default EmailDetail;
