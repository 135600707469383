// there is util folder so I have moved this to arrayUtils.js file
// This file will be removed
export function createEnumDefinition(
  key,
  arr,
  includeDefault = false,
  nameProperty = 'name',
  type = 'string',
  idProperty = 'id'
) {
  const defaultVal = includeDefault && arr.length ? arr[0].id : undefined

  return {
    [key]: {
      type,
      enum: arr?.map((o) => o[idProperty]),
      enumNames: arr?.map((o) => o[nameProperty]),
      default: defaultVal
    }
  }
}

export function createEnumItemsDefinition(
  key,
  arr,
  nameProperty = 'name',
  idProperty = 'id',
  uniqueItems = true
) {
  return {
    [key]: {
      type: 'array',
      items: {
        type: 'string',
        enum: arr.map((o) => o[idProperty]),
        enumNames: arr.map((o) => o[nameProperty])
      },
      uniqueItems: true
    }
  }
}

export function disabledAllFields(
  uiSchema,
  property = { 'ui:disabled': true }
) {
  const newUiSchema = uiSchema
  var array = []
  getAllItemsByKeys(newUiSchema, array)
  array.forEach((a) => {
    a.schema = getMergeSchema(a, property)
  })
  return newUiSchema
}

export function getAllItemsByKeys(obj, arr) {
  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (property === 'values') {
        const values = obj[property]
        const array = values.filter((v) => v.hasOwnProperty('key'))
        if (array.length > 0) {
          arr.push(obj)
        }
      } else if (property === 'key') {
        arr.push(obj)
      } else if (obj[property] instanceof Object) {
        getAllItemsByKeys(obj[property], arr)
      }
    }
  }
}

export function getItemByKeys(obj, arr, keys) {
  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (property === 'values') {
        const values = obj[property]
        const array = values.filter(
          (v) => v.hasOwnProperty('key') && keys.includes(v.key)
        )
        if (array.length > 0) {
          arr.push(obj)
        }
      } else if (property === 'key') {
        if (keys.includes(obj[property])) {
          arr.push(obj)
        }
      } else if (obj[property] instanceof Object) {
        getItemByKeys(obj[property], arr, keys)
      }
    }
  }
}

export function getMergeSchema(obj, property) {
  return {
    ...obj.schema,
    ...property
  }
}
