import React, { useEffect, useState } from 'react';
import objectPath from 'object-path';
import Dropzone from 'react-dropzone';
import { Utils } from 'react-jsonschemapath-form';
import { useTranslation } from 'react-i18next';
import styles from './ImageUpload.scss';
import { getUiOptions } from 'react-jsonschema-form/lib/utils';

import {
  processFile,
  splitDataURL,
  getFileUrl,
  getImageFileUrl
} from '../../../utils/file';
import { uAgent } from '../../../utils/userAgent';

const ImageUpload = (props) => {
  const { t } = useTranslation();
  const key = props.uiSchema.key;
  const [value, setValue] = useState(
    objectPath.get(props.formData, props.uiSchema.key) || {}
  );
  const [options, setOptions] = useState();
  const [maxFileSize, setMaxFileSize] = useState(null);

  useEffect(() => {
    setOptions(getUiOptions(props.uiSchema.schema));
  }, [props?.uiSchema?.schema]);

  useEffect(() => {
    setMaxFileSize(options?.maxFileSize || null);
  }, [options]);

  useEffect(() => {
    if (props.formData) {
      setValue(objectPath.get(props.formData, key) || {});
    }
  }, [props]);

  const onDrop = (files) => {
    if (files.length) {
      const file = files[0];
      if (validateFile(file)) {
        processFile(file).then((fileInfo) => {
          const newProps = {
            value: splitDataURL(fileInfo)
          };
          props.onChange(Utils.getPatch(key, newProps.value));
        });
      } else {
        props.onChange(Utils.getPatch(key, 'error'));
      }
    }
  };

  const validateFile = (file) => {
    if (maxFileSize && file.size > maxFileSize) {
      return false;
    }
    return true;
  };

  const handleKeyDownToClick = (e) => {
    // Trigger the click event from the keyboard
    const code = e.which;
    // 13 = Return, 32 = Space
    if (code === 13 || code === 32) {
      if (e.target.click) {
        e.target.click();
      }
    }
  };

  const { disabled, readonly } = Utils.resolvePathSettings(props);
  const errSchemaPath = Utils.getErrorSchemaPath(props, key);
  let errors =
    errSchemaPath.__errors !== undefined
      ? errSchemaPath.__errors
      : errSchemaPath;

  function traverse(jsonObj) {
    if (jsonObj !== null && typeof jsonObj === 'object') {
      Object.entries(jsonObj).forEach(([key, value]) => {
        // key is either an array index or object key
        traverse(value);
      });
    } else {
      // found an error
      errors = [jsonObj];
    }
  }
  // traverse for errors
  traverse(errors);

  return (
    <div>
      <div
        className={
          styles.files +
          (errors ? ' has-error' : '') +
          ' ' +
          (readonly ? ' readonly' : '')
        }
      >
        <label className='control-label'>
          {t(key)}
          {key && Utils.isRequired(props, key) ? (
            <span className='req'> *</span>
          ) : null}
        </label>
        <div className='row'>
          {readonly ? null : (
            <div className='col-sm-3'>
              <Dropzone
                accept='image/jpeg, image/png, image/gif'
                tabIndex='0'
                onKeyDown={(e) => handleKeyDownToClick(e)}
                onDrop={onDrop}
                disabled={disabled}
                multiple={false}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps({
                      className: styles.dropZone + ' btn'
                    })}
                  >
                    <input {...getInputProps()} />
                    <span className='qjicon-up'> </span>
                    {t('Action.UploadFile')}
                  </div>
                )}
              </Dropzone>
            </div>
          )}
          <div className='col-sm-9'>
            {maxFileSize ? (
              <div>
                {t('Form.Files.MaxFileSize')} {maxFileSize / 1024} kb{' '}
              </div>
            ) : null}
            {value && getFileUrl(value) ? (
              <div className={styles.imageWrap}>
                <h6>{t('Form.Files.Image')}</h6>
                <span className='list-group-title'>{value.name}</span>
                {uAgent() ? (
                  <img
                    src={getImageFileUrl(value)}
                    alt={value.name}
                    className='img-responsive'
                  />
                ) : (
                  <img
                    src={getFileUrl(value)}
                    alt={value.name}
                    className='img-responsive'
                  />
                )}
              </div>
            ) : null}
          </div>
        </div>
        {Utils.errorList(errors)}
      </div>
    </div>
  );
};

ImageUpload.defaultProps = {
  autofocus: false
};

export default ImageUpload;
