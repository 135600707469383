export function base64ToArrayBuffer(data) {
  var binaryString = window.atob(data)
  var binaryLen = binaryString.length
  var bytes = new Uint8Array(binaryLen)
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i)
    bytes[i] = ascii
  }
  return bytes
}

export function downloadPdfFromBase64Data(base64, fileName, mimeType = '') {
  if (typeof mimeType === 'undefined' || mimeType === '') {
    mimeType = 'application/pdf'
  }

  const fileNameDownload = `${fileName}`
  var arrBuffer = base64ToArrayBuffer(base64)
  var newBlob = new Blob([arrBuffer], { type: mimeType })
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob)
    return
  }
  var data = window.URL.createObjectURL(newBlob)
  var link = document.createElement('a')
  document.body.appendChild(link) // required in FF, optional for Chrome
  link.href = data
  link.download = fileNameDownload
  link.click()
  window.URL.revokeObjectURL(data)
  link.remove()
}
