import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#319BC2'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill='white'
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline='central'
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const caculateFinalScorePercentage = (scorepercentage,JobWeight) =>{
  const tempScorePrecentage = { ...scorepercentage };
  tempScorePrecentage.TitleScore =  tempScorePrecentage.TitleScore * JobWeight.job_title * 100 * 2;
  tempScorePrecentage.SkillScore =  tempScorePrecentage.SkillScore *  JobWeight.skill * 100 * 3;
  tempScorePrecentage.NoticePeriod =  tempScorePrecentage.NoticePeriod * JobWeight.notice * 100;
  tempScorePrecentage.ExperienceScore =  tempScorePrecentage.ExperienceScore * JobWeight.experience * 100;
  tempScorePrecentage.LocationScore =  tempScorePrecentage.LocationScore * 100 ;
  return tempScorePrecentage;
};

export class ScoreChart extends PureComponent {
  constructor(props) {
    super(props);
    const scorepercentage  = caculateFinalScorePercentage(this.props.scorepercentage,this.props.jobweightage);
    this.state = {
      data: [
        { name: 'JobTitle', value: (scorepercentage?.TitleScore) },
        { name: 'Skills', value: (scorepercentage?.SkillScore) },
        { name: 'Notice Period', value: (scorepercentage?.NoticePeriod) },
        { name: 'Experience', value: (scorepercentage?.ExperienceScore) },
        { name: 'Location', value: (scorepercentage?.LocationScore) }
      ]
    };
  }

  render() {
    return (
      <PieChart width={400} height={400}>
        <Pie
          data={this.state.data}
          cx='50%'
          cy='50%'
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          fill='#8884d8'
          dataKey='value'
        >
          {this.state.data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    );
  }
}
