import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import deepmerge from 'deepmerge';
import { DropdownList } from 'react-widgets';
import { useTranslation } from 'react-i18next';
import { Utils } from 'react-jsonschemapath-form';
import styles from './Address.scss';

const Address = (props) => {
  const { t } = useTranslation();
  const [stateData, setLocationState] = useState(props.initialData);

  useEffect(() => {
    setLocationState(props.initialData);
  }, [props.initialData]);

  useEffect(() => {
    if (props?.formData?.address.placeName) {
      loadZipCodeData(props?.formData?.address.placeName, props?.formData?.address.postalCode);
    }
  }, [props?.loadZipCodesByCountryData]);

  const loadZipCodeData = (cityData, postalCode) => {
    let selectZipCode;
    const localZipCodes = loadZipCodesByCountryData[cityData];
    if (!isOversea && localZipCodes?.length && localZipCodes?.length === 1) {
      selectZipCode = localZipCodes[0];
    }
    const newState = update(
      stateData,
      Utils.getPatch(
        'zipCode.value',
        selectZipCode ? selectZipCode.id : isOversea ? '' : postalCode || null
      )
    );
    setState(newState);
    updateLocation(
      Utils.getPatch(
        zipCode.key,
        selectZipCode
          ? selectZipCode.value
          : isOversea
          ? ''
          : postalCode || null
      )
    );
  };

  const setState = (options) => {
    setLocationState({
      ...stateData,
      ...options
    });
  };

  const getStateCode = (country, state) => {
    const { loadStateData } = props;
    if (loadStateData[country]) {
      const stateDetail = loadStateData[country].find((s) => s.value === state);
      return stateDetail ? stateDetail.id : null;
    }
  };

  const onChangeCountry = () => {
    const { loadStateData } = props;
    return (event) => {
      if (!event.id) {
        return;
      }
      if (!Object.prototype.hasOwnProperty.call(loadStateData, event.id)) {
        props.getLoadStates(event.id);
      }
      initilise(event.id);
    };
  };

  const initilise = (countryValue) => {
    const { country, state, stateCode, city, zipCode, region, locationCode } =
      stateData;
    const showStates = props.handleCheckShowStates(countryValue);
    let stateValue;
    let cityValue;
    let zipCodeValue;
    let stateCodeValue;
    let regionValue;
    let isOversea = false;
    if (countryValue !== props.defaultCountry) {
      stateValue = 'Default';
      isOversea = false;
      cityValue = 'Default';
      zipCodeValue = 'Default';
      stateCodeValue = 'Default';
      isOversea = true;
    } else {
      regionValue = 'Default';
    }
    let newState = update(
      stateData,
      deepmerge.all([
        Utils.getPatch('country.value', countryValue),
        Utils.getPatch('state.value', stateValue),
        Utils.getPatch('stateCode.value', stateCodeValue),
        Utils.getPatch('city.value', cityValue),
        Utils.getPatch('zipCode.value', zipCodeValue),
        Utils.getPatch('region.value', regionValue),
        Utils.getPatch('locationCode.value', undefined)
      ])
    );
    newState = {
      ...newState,
      isOversea,
      showStates
    };
    setState(newState);

    updateLocation(
      deepmerge.all([
        Utils.getPatch(country.key, newState.country.value),
        Utils.getPatch(state.key, newState.state.value),
        Utils.getPatch(stateCode.key, newState.stateCode.value),
        Utils.getPatch(city.key, newState.city.value),
        Utils.getPatch(zipCode.key, newState.zipCode.value),
        Utils.getPatch(region.key, newState.region.value),
        Utils.getPatch(locationCode.key, newState.locationCode.value)
      ])
    );
  };

  const onChangeState = () => {
    const { loadCityData } = props;
    const { country } = stateData;
    return (event) => {
      if (!event.id) {
        return;
      }
      if (!Object.prototype.hasOwnProperty.call(loadCityData, event.id)) {
        props.getLoadCitiesByCountryStateCode(country.value, event.id);
      }

      const newState = update(
        stateData,
        deepmerge.all([
          Utils.getPatch('state.value', event.value),
          Utils.getPatch('stateCode.value', event.id),
          Utils.getPatch('city.value', null),
          Utils.getPatch('zipCode.value', null)
        ])
      );
      setState(newState);
      updateLocation(
        deepmerge.all([
          Utils.getPatch(country.key, newState.country.value),
          Utils.getPatch(state.key, newState.state.value),
          Utils.getPatch(stateCode.key, newState.stateCode.value),
          Utils.getPatch(city.key, newState.city.value),
          Utils.getPatch(zipCode.key, newState.zipCode.value)
        ])
      );
    };
  };

  const onChangeCity = () => {
    const { loadZipCodesByCountryData } = props;
    const { stateCode, country } = stateData;
    return (event) => {
      if (!event.id) {
        return;
      }
      if (
        !Object.prototype.hasOwnProperty.call(
          loadZipCodesByCountryData,
          event.id
        )
      ) {
        props.getLoadZipCodesByCountry(
          country.value,
          stateCode.value,
          event.id
        );
        updateCity(event);
      } else {
        updateCity(event);
      }
    };
  };

  const updateCity = (event) => {
    const { loadZipCodesByCountryData } = props;
    const { isOversea, city, zipCode } = stateData;
    let selectZipCode;
    const localZipCodes = loadZipCodesByCountryData[event.id];
    if (!isOversea && localZipCodes?.length && localZipCodes?.length === 1) {
      selectZipCode = localZipCodes[0];
    }

    const newState = update(
      stateData,
      deepmerge.all([
        Utils.getPatch('city.value', event.id),
        Utils.getPatch(
          'zipCode.value',
          selectZipCode ? selectZipCode.id : isOversea ? '' : null
        )
      ])
    );
    setState(newState);
    updateLocation(
      deepmerge.all([
        Utils.getPatch(city.key, event.value),
        Utils.getPatch(
          zipCode.key,
          selectZipCode ? selectZipCode.value : isOversea ? '' : null
        )
      ])
    );
  };

  const onChangeZipCode = () => {
    const { zipCode } = stateData;
    return (event) => {
      if (!event.id) {
        return;
      }
      const newState = update(
        stateData,
        Utils.getPatch('zipCode.value', event.id) // TODO: remove hardcoding
      );
      setState(newState);
      updateLocation(Utils.getPatch(zipCode.key, event.id));
    };
  };

  const onChangeStreetAddress = (event) => {
    const { streetAddress } = stateData;
    const value = event.target.value;
    const newState = update(
      stateData,
      Utils.getPatch('streetAddress.value', value)
    );
    setState(newState);
    updateLocation(Utils.getPatch(streetAddress.key, value));
  };

  const onChangeRegion = (event) => {
    const { region } = stateData;
    const value = event.target.value;
    const newState = update(stateData, Utils.getPatch('region.value', value));
    setState(newState);
    updateLocation(Utils.getPatch(region.key, value));
  };

  const onChangeLocationCode = (event) => {
    const { locationCode } = stateData;
    const value = event.target.value;
    const newState = update(
      stateData,
      Utils.getPatch('locationCode.value', value)
    );
    setState(newState);
    updateLocation(Utils.getPatch(locationCode.key, value));
  };

  const updateLocation = (patch) => {
    if (props.onChange) {
      props.onChange(patch);
    }
  };

  const { disabled, readonly } = Utils.resolvePathSettings(props);

  const {
    loadCountryData,
    loadStateData,
    loadCityData,
    loadZipCodesByCountryData,
    initialData,
    placeholder
  } = props;
  const {
    country,
    state,
    stateCode,
    city,
    zipCode,
    streetAddress,
    region,
    locationCode,
    showStates,
    isOversea
  } = stateData;

  const countryErrors = Utils.getErrorSchemaPath(
    props,
    stateData?.country?.key
  ).__errors;
  const stateErrors = Utils.getErrorSchemaPath(
    props,
    stateData?.state?.key
  ).__errors;
  const cityErrors = Utils.getErrorSchemaPath(
    props,
    stateData?.city?.key
  ).__errors;
  const zipCodeErrors = Utils.getErrorSchemaPath(
    props,
    stateData?.zipCode?.key
  ).__errors;
  const regionErrors = Utils.getErrorSchemaPath(
    props,
    stateData?.region?.key
  ).__errors;
  const locationCodeErrors = Utils.getErrorSchemaPath(
    props,
    stateData?.locationCode?.key
  ).__errors;
  const streetAddressErrors = Utils.getErrorSchemaPath(
    props,
    stateData?.streetAddress?.key
  ).__errors;

  return (
    <div className={styles.address}>
      <div
        className={
          'col-xs-3 ' +
          (countryErrors ? 'field has-error' : '') +
          ' ' +
          (readonly ? 'readonly' : '')
        }
      >
        <label className='control-label'>
          {t('Locations.Country')}
          {Utils.isRequired(props, country.key) ? (
            <span className='req'> *</span>
          ) : null}
        </label>
        {readonly && !country.value ? null : (
          <DropdownList
            dataKey='id'
            textField='value'
            value={country.value}
            data={loadCountryData}
            onChange={onChangeCountry()}
            disabled={disabled}
            readOnly={readonly}
            placeholder={placeholder || 'Select option...'}
          />
        )}
        {Utils.errorList(countryErrors)}
      </div>
      <div
        className={
          'col-xs-12 ' +
          (streetAddressErrors ? 'field has-error' : '') +
          ' ' +
          (readonly ? 'readonly' : '')
        }
      >
        <label className='control-label'>
          {t('Locations.StreetAddress')}
          {Utils.isRequired(props, streetAddress?.key) ? (
            <span className='req'> *</span>
          ) : null}
        </label>
        {readonly && !streetAddress?.value ? null : (
          <input
            data-testid='streetAddressTest'
            type='text'
            className='form-control'
            value={streetAddress?.value}
            onChange={onChangeStreetAddress}
            maxLength='75'
            autoFocus={initialData?.options?.autofocus || false}
            disabled={disabled}
            readOnly={readonly}
          />
        )}
        {Utils.errorList(streetAddressErrors)}
      </div>
      {showStates ? (
        <div className={styles.selectLocation}>
          {loadStateData[country?.value] ? (
            <div>
              <div
                className={
                  'col-xs-3 ' +
                  (stateErrors ? 'field has-error' : '') +
                  ' ' +
                  (readonly ? 'readonly' : '')
                }
              >
                <label className='control-label'>
                  {t('Locations.State')}
                  {Utils.isRequired(props, state.key) ? (
                    <span className='req'> *</span>
                  ) : null}
                </label>
                {readonly && !stateCode.value ? null : (
                  <DropdownList
                    dataKey='id'
                    textField='value'
                    value={stateCode.value === 'Default' ? '' : stateCode.value}
                    data={loadStateData[country.value]}
                    onChange={onChangeState()}
                    disabled={disabled}
                    readOnly={readonly}
                    placeholder={placeholder || 'Select option...'}
                    autoFocus={initialData?.options?.autofocus || false}
                  />
                )}
                {Utils.errorList(stateErrors)}
              </div>
              <div
                className={
                  'col-xs-3 ' +
                  (cityErrors ? 'field has-error' : '') +
                  ' ' +
                  (readonly ? 'readonly' : '')
                }
              >
                <label className='control-label'>
                  {t('Locations.City')}
                  {Utils.isRequired(props, city.key) ? (
                    <span className='req'> *</span>
                  ) : null}
                </label>
                {readonly && !city.value ? null : (
                  <DropdownList
                    dataKey='id'
                    textField='value'
                    value={city.value}
                    data={
                      loadCityData[state.value] ||
                      loadCityData[getStateCode(country.value, state.value)]
                    }
                    onChange={onChangeCity()}
                    disabled={disabled}
                    readOnly={readonly}
                    placeholder={placeholder || 'Select option...'}
                  />
                )}
                {Utils.errorList(cityErrors)}
              </div>
              <div
                className={
                  'col-xs-3 ' +
                  (!isOversea && zipCodeErrors ? 'field has-error' : '') +
                  ' ' +
                  (readonly ? 'readonly' : '')
                }
              >
                <label className='control-label'>
                  {t('Locations.ZipCode')}
                  {!isOversea && Utils.isRequired(props, zipCode.key) ? (
                    <span className='req'> *</span>
                  ) : null}
                </label>
                {readonly && !zipCode.value ? null : (
                  <DropdownList
                    dataKey='id'
                    textField='value'
                    value={zipCode.value}
                    data={loadZipCodesByCountryData[city.value]}
                    onChange={onChangeZipCode()}
                    disabled={disabled}
                    readOnly={readonly}
                    placeholder={placeholder || 'Select option...'}
                  />
                )}
                {Utils.errorList(zipCodeErrors)}
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div className={styles.selectLocation}>
          <div>
            <div
              className={
                'col-xs-8 ' +
                (regionErrors ? 'field has-error' : '') +
                ' ' +
                (readonly ? 'readonly' : '')
              }
            >
              <label className='control-label'>
                {t('Locations.Region')}
                {Utils.isRequired(props, region?.key) ? (
                  <span className='req'> *</span>
                ) : null}
              </label>
              {readonly && !region?.value ? null : (
                <input
                  data-testid='regionTest'
                  type='text'
                  className='form-control'
                  value={region?.value}
                  onChange={onChangeRegion}
                  maxLength='75'
                  disabled={disabled}
                  readOnly={readonly}
                />
              )}
              {Utils.errorList(regionErrors)}
            </div>
            <div
              className={
                'col-xs-4 ' +
                (locationCodeErrors ? 'field has-error' : '') +
                ' ' +
                (readonly ? 'readonly' : '')
              }
            >
              <label className='control-label'>
                {t('Locations.LocationCode')}
                {Utils.isRequired(props, locationCode?.key) ? (
                  <span className='req'> *</span>
                ) : null}
              </label>
              {readonly && !locationCode?.value ? null : (
                <input
                  type='text'
                  className='form-control'
                  value={locationCode?.value}
                  onChange={onChangeLocationCode}
                  maxLength='75'
                  disabled={disabled}
                  readOnly={readonly}
                />
              )}
              {Utils.errorList(locationCodeErrors)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Address.propTypes = {
  countries: PropTypes.array,
  states: PropTypes.object,
  cities: PropTypes.object,
  zipCodes: PropTypes.object,
  defaultPlaceholder: PropTypes.string
};

Address.defaultProps = {
  defaultPlaceholder: 'Select option...'
};

export default Address;
