import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './EmptyList.scss'

const EmptyList = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.emptyList}>
      <div className='well well-lg'>
        <h5 className='text-center'>{t('List.NoResults')}</h5>
      </div>
    </div>
  )
}
export default EmptyList
