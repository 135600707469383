import React from 'react';
import { ConstValues } from '../../../utils/constValues';

export default function EmailStatusIcon(props) {
  const { emailStatus } = props;
  const getIconFromValue = (value) => {
    switch (parseInt(value)) {
      case ConstValues.EmailStatus.Seen:
        return 'green';

      case ConstValues.EmailStatus.Failed:
      case ConstValues.EmailStatus.Pending:
        return 'red';

      case ConstValues.EmailStatus.UnSeen:
      case ConstValues.EmailStatus.Success:
        return 'orange';

      default:
        return 'default';
    }
  };

  const flagClassName = 'qjicon-email-status-' + getIconFromValue(emailStatus);

  return <span data-testid='statusIconTest' className={flagClassName} />;
}
