export const ConstValues = {
  EmailStatus: {
    NotSent: 0,
    Pending: 1,
    Success: 2,
    Failed: 3,
    Seen: 4,
    UnSeen: 5
  },
  NoteFormConstants: {
    HIRINGMANAGER_ACCESS_FLAG_CONST: 'hasHiringManagerInAction',
    HIRINGMANAGER_ACCESSTYPENOTES_CONST: 'Employment Document'
  }
};
