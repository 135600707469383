import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
// import { connect } from "react-redux";
// import * as notifActions from "../../redux/actions/notifs";
import IntlFieldTemplate from './IntlFieldTemplate'
import PathForm from 'react-jsonschemapath-form'
import { customFields } from './fields'
import { customWidgets } from './widgets'
import { Button } from 'react-bootstrap'
import NavigationPrompt from '../common/NavigationPrompt'
import stylesForm from './QJumpersForm.scss'
import PopError from '../common/ErrorHandler'

class QJumpersForm extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    submitButtonText: PropTypes.string,
    cancelButtonDisabled: PropTypes.bool,
    cancelButtonText: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    onCancel: PropTypes.func,
    noPrompt: PropTypes.bool,
    customPrompt: PropTypes.object,
    route: PropTypes.object,
    // reload prop is create specifically for Email templates issue where after selecting email template no subject and message was loaded.
    // this prop just check is reload prop is present and if present is update form data.
    reload: PropTypes.string
  }

  static defaultProps = {
    noPrompt: false,
    customPrompt: null
  }

  constructor(props) {
    super(props)
    this.state = {
      pristine: true,
      formData: props.initialValues || {},
      customFields: {
        ...customFields,
        ...props.customFields
      },
      customWidgets: {
        ...customWidgets,
        ...props.customWidgets
      },
      validatedError: [],
      popErrorData: []
    }
    this.onFormStop = this.onFormStop.bind(this)
    this.onForceStop = this.onForceStop.bind(this)
    this.onFormDataChange = this.onFormDataChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reload && prevProps.reload !== this.props.reload) {
      this.setState({ formData: this.props.initialValues })
    }
  }

  onFormStop = () => {
    const { pristine } = this.state
    const { onCancel, t } = this.props
    if (!pristine) {
      this.setState({
        validatedError: [pristine],
        popErrorData: [
          {
            type: 'warning',
            title: t('Form.Unsaved.Title'),
            message: t('Form.Unsaved.Text'),
            confirmBtnText: t('Form.Unsaved.Leave'),
            onConfirm: () => {
              this.setState({ pristine: true, validatedError: [] }, () => {
                if (onCancel) {
                  onCancel()
                }
              })
            },
            showCancel: true
          }
        ]
      })
    } else {
      if (onCancel) {
        onCancel()
      }
    }
  }

  onForceStop = () => {
    const { initialValues, formData, formStop, onCancel } = this.props
    this.setState({
      formData: initialValues,
      liveValidate: false,
      submitting: false,
      pristine: true
    })
    if (formStop) {
      formStop(formData)
    }
    if (onCancel) {
      onCancel()
    }
  }

  onFormDataChange = ({ formData }) => {
    const { onChange } = this.props
    this.setState(
      {
        formData,
        pristine: false
      },
      () => {
        if (onChange) {
          onChange(formData)
        }
      }
    )
  }

  onFormSubmit = ({ formData }) => {
    const { onSubmit, schema, t } = this.props
    const { form } = this.refs

    if (form) {
      this.setState(
        { liveValidate: false, submitting: true, pristine: true },
        () => {
          // Validate Form
          const validation = form.validate(formData, schema)
          if (validation.errors.length) {
            // INVALID
            this.setState({
              submitting: false,
              pristine: false,
              validatedError: validation.errors,
              popErrorData: [
                {
                  type: 'warning',
                  title: t('Form.Errors.Title'),
                  message: t('Form.Errors.PleaseCorrect'),
                  confirmBtnText: t('Action.Ok'),
                  onConfirm: () => {
                    this.setState({ liveValidate: true, validatedError: [] })
                  },
                  showCancel: false
                }
              ]
            })

            // return (
            //   <PopError
            //     errors={{
            //       type: 'warning',

            //       title: t('Form.Errors.Title'),

            //       message: t('Form.Errors.PleaseCorrect'),

            //       confirmBtnText: t('Action.Ok'),

            //       onConfirm: () => {
            //         this.setState({ liveValidate: true })
            //       },

            //       showCancel: false
            //     }}
            //   />
            // )
            // <PopError error:{
            //       type: 'warning',
            //       title: t('Form Errors Title'),
            //       message: t('Form Errors PleaseCorrect'),
            //       confirmBtnText: t('Action Ok'),
            //       onConfirm: () => {
            //         this.setState({ liveValidate: true })
            //       },
            //       showCancel: false
            //     } />
          } else {
            // VALID
            if (onSubmit) {
              onSubmit(formData)
                .then(() => this.setState({ submitting: false }))
                .catch(() =>
                  this.setState({ submitting: false, pristine: false })
                )
            }
          }
        }
      )
    }
  }

  render() {
    const { formData, liveValidate, submitting, customFields, customWidgets } =
      this.state

    const {
      schema,
      uiSchema,
      children,
      customValidate,
      customPrompt,
      noPrompt,
      cancelButtonDisabled,
      submitButtonText,
      cancelButtonText,
      t,
      value
    } = this.props

    return (
      <div>
        {customPrompt || (
          <NavigationPrompt when={!noPrompt && !this.state.pristine} />
        )}
        <PathForm
          schema={schema}
          uiSchema={uiSchema}
          formData={formData}
          // onChange={() => this.onFormDataChange}
          onChange={value ? () => this.onFormDataChange : this.onFormDataChange}
          className={stylesForm.qjumpersForm}
          liveValidate={liveValidate}
          validate={customValidate}
          showErrorList={false}
          FieldTemplate={IntlFieldTemplate}
          fields={customFields}
          widgets={customWidgets}
          ref='form'
          tagName='div'
        >
          {children || (
            <div className='form-actions'>
              <Button
                type='button'
                bsStyle='primary'
                bsSize='lg'
                className='btn btn-lg btn-primary'
                onClick={() => this.onFormSubmit({ formData })}
                disabled={submitting}
              >
                {submitButtonText || t('Action.Save')}
              </Button>
              {cancelButtonDisabled ? null : (
                <Button
                  type='button'
                  bsStyle='default'
                  bsSize='lg'
                  className='btn-border btn btn-lg btn-default'
                  onClick={this.onFormStop}
                >
                  {cancelButtonText || t('Action.Cancel')}
                </Button>
              )}
            </div>
          )}
        </PathForm>
        {this.state.validatedError.length > 0 ? (
          <PopError errors={this.state.popErrorData} />
        ) : null}
      </div>
    )
  }
}

export default withTranslation()(QJumpersForm)
