import React from 'react';
import DocumentUrl from '../common/DocumentUrl';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import styles from './NoteList.scss';
import moment from 'moment';

const NoteList = (props) => {
  const { t } = useTranslation();

  const isSubstatusMove = (noteType) => {
    return noteType.name.toLowerCase() === 'substatusmove';
  };

  const isWithdraw = (noteType) => {
    return noteType.name.toLowerCase() === 'withdraw';
  };

  const getNoteTypeClass = (noteTypeId, notetypes) => {
    const noteType = notetypes.find(type => type.id === noteTypeId);
    return noteType ? _getIconFromValue(noteType.name) : 'chat';
  };

  const getNoteHeading = (note, noteTypes) => {
    const { isEditDeleteNoteEnabled } = props;

    const noteType = noteTypes.find(type => type.id === note.noteTypeId);
    const noteTypeName = noteType && noteType.userSelectable ? `${noteType.name} Notes` : null;

    const noteText = note ? getNoteText(note, noteType) : '';
    const additionalText = note && !note.isDeleted ? getNoteAdditionalText(note, noteType) : null;

    return (
      <div className="row">
        <div className="col-md-9">
          {noteTypeName && (
              <div className={styles.headerbold + ' list-group-item-heading'}>{noteTypeName}</div>
           )}
          <div className={!noteTypeName && noteText ? styles.headerbold + ' list-group-item-heading' : ''}>{noteText}</div>
          {additionalText && <div>{additionalText}</div>}
        </div>
        {isEditDeleteNoteEnabled && !note.isDeleted && noteType.userSelectable && (
          <div className="col-md-3">
            <Button data-testid="editNote" bsStyle="default" bsSize="small" onClick={() => handleEditNote(note.id)}>
              <span className="glyphicon glyphicon-pencil"> </span>
            </Button>
            <Button data-testid="deleteNote" bsStyle="danger" bsSize="small" onClick={() => handleDeleteNote(note.id)}>
              <span className="glyphicon glyphicon-trash"> </span>
            </Button>
          </div>
        )}
      </div>
    );
  };

  const getAuditHistory = (note) => {
    const mergedHistory = [
      ...(Array.isArray(note.auditHistory) ? note.auditHistory : []), 
      { createdBy: note.createdBy, createdDate: note.createdDate }
    ];

    return mergedHistory.map((history, index) => (
      <p key={index} className={styles.smallgray + ' list-group-item-text'}>
        <span>
          {moment(new Date(history.createdDate)).format(
            'DD/M/YYYY\xa0\xa0'
          )}
        </span>
        <span>{moment(new Date(history.createdDate)).format('LT')}</span>
        <span>{history.createdBy}</span>
    </p>
    ));
  };

  const valueMap = {
    'general': 'chat',
    'phone interview': 'phone',
    'reference check': 'search',
    'interview': 'q',
    'substatusmove': 'bars',
    'audit': 'bars',
    'emails': 'mail',
    'flags': 'flag',
  };

  const _getIconFromValue = (value) => {
    const defaultValue = 'chat';
    if (!value) return defaultValue;
    
    const normalizedValue = value.toLowerCase();
    return valueMap[normalizedValue] || defaultValue;
  };

  const getNoteText = (note, noteType) => {
    if (noteType) {
      if (isSubstatusMove(noteType) || isWithdraw(noteType)) {
        return t(note.noteText);
      } else {
        return (
          <div
            contentEditable='false'
            dangerouslySetInnerHTML={{ __html: note.noteText }}
          />
        );
      }
    }
    return null;
  };

  const getAdditionalText = (note) => {
    return(
      <span>
      {note.noteAdditionalText ? note.noteAdditionalText : ''}
    </span>
    );
  };

  const getNoteAdditionalText = (note, noteType) => {
    if (noteType) {
      if (isSubstatusMove(noteType)) {
        const d = new Date(note.noteAdditionalText);
        if (d.getFullYear() && d.getMonth() && d.getDate()) {
        } else {
          return getAdditionalText(note);
        }
      } else if (isWithdraw(noteType)) {
        const originalExpiryDate = new Date(note.noteAdditionalText);
        if (
          originalExpiryDate.getFullYear() &&
          originalExpiryDate.getMonth() &&
          originalExpiryDate.getDate()
        ) {
          return (
            <div>
              {t('Note.AdditionalText.Withdraw')}
              {moment(originalExpiryDate).format('DD MMM YY')}
            </div>
          );
        } else {
          return getAdditionalText(note);
        }
      } else {
        return (
          <div
            contentEditable='false'
            dangerouslySetInnerHTML={{ __html: note.noteAdditionalText }}
          />
        );
      }
    }
    return null;
  };

  const handleAddNote = () => {
    const { onAddNote } = props;

    if (onAddNote) onAddNote();
  };

  const handleEditNote = (noteId) => {
    const { onEditNote } = props;

    if (onEditNote) onEditNote(noteId);
  };

  const handleDeleteNote = (noteId) => {
    const { onDeleteNote } = props;

    if (onDeleteNote) onDeleteNote(noteId);
  };

  const handleViewMore = (note) => {
    const { onViewMore } = props;

    if (onViewMore) onViewMore(note);
  };

  const {
    notes,
    noteTypes,
    isAddNoteEnabled,
    onViewEmail,
    hasCommunicationRights
  } = props;

  return (
    <div className={styles.notes}>
      {notes && notes.length ? (
        <div className={styles.noteListItems}>
          {notes.map((note) => (
            <div key={note.id}>
              <div className={styles.bottomborder + ' list-group'}>
                <span
                  className={
                    'pull-left btn btn-sm btn-icon qjicon-' +
                    getNoteTypeClass(note.noteTypeId, noteTypes)
                  }
                />
                {getNoteHeading(note, noteTypes)}
                {getAuditHistory(note)}
                {hasCommunicationRights &&
                getNoteTypeClass(note.noteTypeId, noteTypes) === 'mail' ? (
                  <Button
                    bsClass='action'
                    className={
                      styles.btnborder + ' btn btn-default btn-sm btn-border'
                    }
                    onClick={() => onViewEmail(note.id)}
                  >
                    {t('note.ViewEmail')}
                  </Button>
                ) : null}
                {note.noteText.includes('AI Score') ? (
                  <Button
                    bsClass='action'
                    className={
                      styles.btnborder + ' btn btn-default btn-sm btn-border'
                    }
                    onClick={() => handleViewMore(note)}
                  >
                    {t('note.viewMore')}
                  </Button>
                ) : null}
                {note.document ? (
                  <div>
                    <DocumentUrl
                      getDocumentContent={props.getDocumentContent}
                      file={note.document}
                      styles={
                        styles.btnborder + ' btn btn-default btn-sm btn-border'
                      }
                      organisationId={props.organisationId}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className='well well-lg'>
          <h5 className='text-center'>{t('List.Results')}</h5>
        </div>
      )}
      {isAddNoteEnabled ? (
        <Button
          bsClass='action'
          className={styles.uppercase + ' btn btn-success'}
          onClick={handleAddNote}
        >
          {t('Form.Note.NewNote')}
        </Button>
      ) : null}
    </div>
  );
};

export default NoteList;
