export const surveyStrings = {
  // survey templates
  survey: {
    dropQuestion: 'Please drop a question here.',
    copy: 'Copy',
    addToToolbox: 'Add to toolbox',
    deleteQuestion: 'Delete'
  },
  // questionTypes
  qt: {
    checkbox: 'Checkbox',
    comment: 'Textarea',
    dropdown: 'Dropdown',
    file: 'File',
    html: 'Html',
    matrix: 'Matrix (single choice)',
    matrixdropdown: 'Matrix (multiple choice)',
    matrixdynamic: 'Matrix (dynamic rows)',
    multipletext: 'Multiple Text',
    radiogroup: 'Radiogroup',
    rating: 'Rating',
    text: 'Single Input'
  },
  // Strings in Editor
  ed: {
    addNewPage: 'Add New Page',
    newPageName: 'page',
    newQuestionName: 'question',
    testSurvey: 'Preview',
    testSurveyAgain: 'Repeat Preview',
    testSurveyWidth: 'Questionnaire width: ',
    embedSurvey: 'Embed Questionnaire',
    saveSurvey: 'Accept & Proceed',
    designer: 'Designer',
    jsonEditor: 'JSON',
    undo: 'Undo',
    redo: 'Redo',
    options: 'Options',
    generateValidJSON: 'Generate Valid JSON',
    generateReadableJSON: 'Generate Readable JSON',
    toolbox: 'Toolbox',
    delSelObject: 'Delete selected object',
    correctJSON: 'Please correct JSON.',
    surveyResults: 'Questionnaire Result: '
  },
  // Property Editors
  pe: {
    apply: 'Apply',
    ok: 'OK',
    cancel: 'Cancel',
    reset: 'Reset',
    close: 'Close',
    delete: 'Delete',
    addNew: 'Add New',
    removeAll: 'Remove All',
    edit: 'Edit',
    empty: '<empty>',
    fastEntry: 'Fast Entry',
    formEntry: 'Form Entry',
    testService: 'Test the service',
    expressionHelp:
      'Please enter a boolean expression. It should return true to make/keep the question visible. For example: {Id} = "value1" or ({Id} = 3 and {Id} < 5)',

    propertyIsEmpty: 'Please enter value into the property',
    value: 'Value',
    text: 'Text',
    required: 'Required?',
    columnEdit: 'Edit column: {0}',

    hasOther: 'Has other item',
    name: 'Name',
    title: 'Question',
    score: 'Score',
    action: 'Action',
    cellType: 'Cell type',
    colCount: 'Column count',
    choicesOrder: 'Select choices order',
    visible: 'Is visible?',
    isRequired: 'Is required?',
    startWithNewLine: 'Is start with new line?',
    rows: 'Row count',
    placeHolder: 'Input place holder',
    showPreview: 'Is image preview shown?',
    storeDataAsText: 'Store file content in JSON result as text',
    maxSize: 'Maximum file size in bytes',
    imageHeight: 'Image height',
    imageWidth: 'Image width',
    rowCount: 'Row count',
    addRowText: 'Add row button text',
    removeRowText: 'Remove row button text',
    minRateDescription: 'Minimum rate description',
    maxRateDescription: 'Maximum rate description',
    inputType: 'Input type',
    optionsCaption: 'Options caption',

    qEditorTitle: 'Edit', // question: {0}",
    tabs: {
      general: 'General',
      fileOptions: 'Options',
      html: 'Html Editor',
      columns: 'Columns',
      rows: 'Rows',
      items: 'Items',
      validators: 'Validators',
      choices: 'Choices',
      visibleIf: 'Visible If',
      rateValues: 'Rate Values',
      choicesByUrl: 'Choices from Web',
      matrixChoices: 'Default Choices',
      multipleTextItems: 'Text Inputs'
    },

    editProperty: 'Edit property "{0}"',
    items: '[ Items: {0} ]',

    enterNewValue: 'Please, enter the value.',
    noquestions: 'There is no any question in the Questionnaire.',
    createtrigger: 'Please create a trigger',
    triggerOn: 'On ',
    triggerMakePagesVisible: 'Make pages visible:',
    triggerMakeQuestionsVisible: 'Make questions visible:',
    triggerCompleteText: 'Complete the Questionnaire if succeed.',
    triggerNotSet: 'The trigger is not set',
    triggerRunIf: 'Run if',
    triggerSetToName: 'Change value of: ',
    triggerSetValue: 'to: ',
    triggerIsVariable: 'Do not put the variable into the Questionnaire result.',
    verbChangeType: 'Change type ',
    verbChangePage: 'Change page '
  },
  // Operators
  op: {
    empty: 'is empty',
    notempty: 'is not empty',
    equal: 'equals',
    notequal: 'not equals',
    contains: 'contains',
    notcontains: 'not contains',
    greater: 'greater',
    less: 'less',
    greaterorequal: 'greater or equals',
    lessorequal: 'Less or Equals'
  },
  // Embed window
  ew: {
    angular: 'Use Angular version',
    jquery: 'Use jQuery version',
    knockout: 'Use Knockout version',
    react: 'Use React version',
    vue: 'Use Vue version',
    bootstrap: 'For bootstrap framework',
    standard: 'No bootstrap',
    showOnPage: 'Show Questionnaire on a page',
    showInWindow: 'Show Questionnaire in a window',
    loadFromServer: 'Load Questionnaire JSON from server',
    titleScript: 'Scripts and styles',
    titleHtml: 'HTML',
    titleJavaScript: 'JavaScript'
  },
  // Properties
  p: {
    name: 'name',
    title: {
      name: 'question',
      title: 'Leave it empty, if it is the same as "Name"'
    },
    survey_title: { name: 'title', title: 'It will be shown on every page.' },
    page_title: { name: 'title', title: 'Page title' }
  },
  pagePrevText: 'Previous',
  pageNextText: 'Next',
  completeText: 'Save Answers',
  otherItemText: 'Other',
  progressText: 'Page {0} of {1}',
  emptySurvey: 'No visible questions are available.',
  completingSurvey: 'Thank you for completing the questionnaire.',
  loadingSurvey: 'The questionnaire is loading please wait a moment...',
  optionsCaption: 'Options...',
  requiredError: 'Please answer the question.',
  requiredInAllRowsError: 'Please complete all rows in the question.',
  numericError: 'Please enter a valid number.',
  textMinLength: 'Text must be at least {0} characters.',
  minSelectError: 'Please select more than {0} responses.',
  maxSelectError: 'Please select less than {0} responses.',
  numericMinMax:
    "'{0}' number entered should be more than {1} and less than {2}",
  numericMin: "'{0}' number entered should be more than {1}",
  numericMax: "'{0}' number entered should be less than {1}",
  invalidEmail: 'Please enter a valid email address.',
  exceedMaxSize: 'Please load an image no larger than {0}.',
  otherRequiredError: "Please enter text in the 'Other' field."
}
