import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import objectPath from 'object-path';
import { getUiOptions } from 'react-jsonschema-form/lib/utils';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';
import styles from './MediaChooser.scss';
import { Utils } from 'react-jsonschemapath-form';

const MediaChooser = (props) => {
  const { t } = useTranslation();
  const [state, setMediaState] = useState({});

  useEffect(() => {
    initialise(props);
    const key = props.uiSchema.key;

    setState({
      key: key
    });
  }, []);

  const setState = (options) => {
    setMediaState({
      ...state,
      ...options
    });
  };

  const initialise = (props) => {
    const options = props.uiSchema.schema
      ? getUiOptions(props.uiSchema.schema)
      : {};

    const subKey = props.uiSchema.key.substring(
      props.uiSchema.key.lastIndexOf('.') + 1
    );
    const images =
      objectPath.get(props.schema.definitions, [subKey, 'images']) || [];
    const value = objectPath.get(props.formData, props.uiSchema.key) || [];

    const newState = {
      ...options,
      images: Array.isArray(images) ? images : [],
      value: value,
      init: true
    };
    // return newState;
    setState({ newState });
  };

  const onChange = () => {
    const { value } = state;
    updateValue(!value);
  };

  const updateValue = (value) => {
    const newState = update(state, Utils.getPatch('value', value));
    setState(newState);
    props.onChange(Utils.getPatch(state.key, value));
  };

  const { key, images, init, value, options } = state;

  return init ? (
    <div className={styles.mediaChooser}>
      <label className='control-label'>{t(key)}</label>
      <div className={styles.mediaChooserImgWrapper}>
        {images ? (
          images.map((mi, index) => {
            return (
              <MediaChooserInput
                key={index}
                id={mi.image.id}
                image={mi.image}
                inputKey={key}
                name={mi.name}
                jobBoardBrandType={mi.jobBoardBrandType}
                value={value === mi.image.id}
                deleted={mi.deleted}
                options={options}
                onChange={onChange}
              />
            );
          })
        ) : (
          <div>No media to select</div>
        )}
      </div>
    </div>
  ) : null;
};

MediaChooser.propTypes = {
  formData: PropTypes.object,
  value: PropTypes.array,
  uiSchema: PropTypes.shape({
    'ui:placeholder': PropTypes.string
  })
};

export default MediaChooser;

const MediaChooserInput = (props) => {
  const [state, setMediaChooseState] = useState({});

  useEffect(() => {
    const options = props.options || null;

    setState({
      options: options
    });
  }, []);

  const setState = (options) => {
    setMediaChooseState({
      ...state,
      ...options
    });
  };

  const renderImage = (image, width, height) => {
    const dimensionsStyle = {
      maxWidth: width,
      maxHeight: height
    };
    if (image) {
      if (image.url) {
        return (
          <div className={styles.mediaChooserImgCont}>
            <img
              className={styles.mediaChooserImg}
              src={image.url}
              alt={image.id}
              style={dimensionsStyle}
            />
          </div>
        );
      }
    }

    return null;
  };

  const renderLabel = (label) => {
    if (label) {
      return <span className={styles.mediaChooserLabel}>{label}</span>;
    }
    return null;
  };

  const renderInput = (val = true, type, advertiserBrandId) => {
    const { id } = props;
    const { options } = state;

    const disabled = options ? options.disabled : null;
    const readonly = options ? options.readonly : null;
    const autofocus = options ? options.autofocus : null;

    let inputClass = styles.mediaChooserRadio;
    let inputType = 'radio';
    if (type === 4) {
      inputClass = styles.mediaChooserCheckbox;
      inputType = 'checkbox';
    }
    return (
      <input
        className={inputClass}
        type={inputType}
        id={id}
        name={advertiserBrandId}
        checked={val}
        onChange={props.onChange}
        disabled={disabled}
        readOnly={readonly}
        autoFocus={autofocus}
      />
    );
  };

  const { id, deleted, image, name, value, jobBoardBrandType, inputKey } =
    props;

  const { options } = state;
  const width = options ? options.width : 120;
  const height = options ? options.width : 120;

  let val = false;
  if (value === id) {
    val = true;
  }

  if (!deleted) {
    return (
      <div className={styles.mediaChooserInput}>
        {renderImage(image, width, height)}
        {renderLabel(name)}
        {renderInput(val, jobBoardBrandType, inputKey)}
      </div>
    );
  }
  return null;
};

MediaChooserInput.propTypes = {
  name: PropTypes.string,
  advertiserBrandId: PropTypes.number,
  jobBoardBrandType: PropTypes.number,
  deleted: PropTypes.bool,
  image: PropTypes.object,
  inputKey: PropTypes.string
};
