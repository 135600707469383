import React, { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'

const ToastHandler = (props) => {
  // for componentWillReceiveProps
  useEffect(() => {
    if (props.toast && props.toast.length) {
      _toast(props.toast[0])
    }
  }, [props.toast])

  const DetailMessage = ({ t }) => {
    return (
      <div style={{ display: 'block' }}>
        <div>
          <span>{t.title}</span>
        </div>
        <div style={{ fontSize: '.8em' }}>{t.detail || t.message}</div>
      </div>
    )
  }

  // const messageInputCheck = (t) => {
  //   if (t.detail) {
  //     toast.success(<DetailMessage t={t} />)
  //   } else {
  //     toast.success(`${t.message}`)
  //   }

  // };

  const _toast = (toastProps) => {
    const defaults = {
      type: 'success',
      message: "That's a success!",
      detail: '',
      title: ''
    }
    const t = { ...defaults, ...toastProps }
    switch (t.type.toLowerCase()) {
      case 'info':
        toast.info(<DetailMessage t={t} />)
        break
      case 'warning':
        toast.warn(<DetailMessage t={t} />)
        break
      case 'error':
        toast.error(<DetailMessage t={t} />)
        // toast.error(`${t.message}`)
        break
      default:
        toast.success(<DetailMessage t={t} />)
    }

    if (props.clearToasts) {
      props.clearToasts()
    }
  }

  return (
    <React.Fragment>
      <ToastContainer
        position='top-right'
        autoClose={6000}
        hideProgressBar
        newestOnTop={false}
        // closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
    </React.Fragment>
  )
}

export default ToastHandler
