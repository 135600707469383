import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';
import objectPath from 'object-path';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';
import { Utils } from 'react-jsonschemapath-form';

export default function CvParsingButton(props) {
  const { t } = useTranslation();

  const multiple = props.multiple || false;
  const key = props.uiSchema.key;
  const [parsingCv, setParsingCv] = useState();
  const [parsingCvError, setParsingCvError] = useState();

  const value =
    objectPath.get(props.formData, key) || (props.multiple ? [] : {});

  const valueData =
    value && value.upload ? (props.multiple ? value : [value]) : [];

  const [state, setButtonState] = useState({
    key: key,
    values: {
      new: valueData
    }
  });

  useEffect(() => {
    showCvParsingAlert(state.values);
  }, []);

  useEffect(() => {
    const isNewValue = (value) =>
      value &&
      value.upload &&
      value.id === '00000000-0000-0000-0000-000000000000';
    const value =
      objectPath.get(props.formData, state.key) || (multiple ? [] : {});
    const values = multiple ? value : [value];

    if (values && values.length > 0 && !values[0].parsingCvResponse) {
      const newState = update(
        state,
        Utils.getPatch('values.new', values.filter(isNewValue))
      );

      setState(newState);

      const cv = {
        new: values.filter(isNewValue)
      };

      showCvParsingAlert(cv);
    }
  }, [props.formData, state.key]);

  const setState = (options) => {
    setButtonState({
      ...state,
      ...options
    });
  };

  const handleClick = () => {
    const { parseCv, setCvParsingResponseData } = props;

    if (!parseCv && !setCvParsingResponseData) return;

    setParsingCv(true);

    parseCv(state.values.new[0]).then((result) => {
      setParsingCv(false);

      if (!result) {
        setParsingCvError(true);
      } else {
        setCvParsingResponseData(result);
        updateAllowCvParsing(result.data);
      }
    });
  };

  const hideAlert = () => {
    updateAllowCvParsing();
    setParsingCvError(false);
  };

  const updateAllowCvParsing = () => {
    var { values } = state;
    if (values && values.new.length > 0) {
      values.new[0].allowCvParsing = false;
      onChange(values.new[0]);
    }
  };

  const onChange = (values) => {
    const { onChange } = props;

    if (onChange) onChange(Utils.getPatch(state.key, values));
  };

  const showCvParsingAlert = (values) => {
    const { popError } = props;

    if (
      values.new &&
      values.new.length &&
      typeof values.new[0].allowCvParsing === 'undefined' &&
      popError
    ) {
      popError({
        type: 'info',
        title: t('FieldWidgets.CvParsingButton.ParsingCvModal.Confirm.Heading'),
        message: t(
          'FieldWidgets.CvParsingButton.ParsingCvModal.Confirm.Message'
        ),
        confirmBtnText: 'Autofill profile',
        cancelBtnText: t('Modal.Unsaved.Cancel'),
        onConfirm: () => handleClick(),
        onCancel: () => hideAlert(),
        showCancel: true
      });
    }
  };

  const { values } = state;
  const readonly = Utils.isReadonly(props);

  return (
    <div>
      <div className={readonly ? ' readonly' : ''}>
        {values.new && values.new.length ? (
          <div className='row'>
            <div className='col-sm-6'>
              <Button
                bsStyle='success'
                className='btn-next'
                onClick={handleClick}
              >
                {t('FieldWidgets.CvParsingButton.ParseCvButtonText')}
              </Button>
            </div>
          </div>
        ) : null}
      </div>

      {parsingCv ? (
        <div className='swal-btns-hidden'>
          <SweetAlert
            type='info'
            title={t('FieldWidgets.CvParsingButton.ParsingCvModal.Heading')}
            showConfirmButton={false}
            onConfirm={hideAlert}
          >
            <span>
              <span>
                {t('FieldWidgets.CvParsingButton.ParsingCvModal.Message')}
              </span>
            </span>
          </SweetAlert>
        </div>
      ) : null}

      {parsingCvError ? (
        <SweetAlert
          confirmBtnText={t('Action.Ok')}
          confirmBtnBsStyle='danger'
          cancelBtnBsStyle='default'
          onConfirm={hideAlert}
          type='error'
          title={t('FieldWidgets.CvParsingButton.ParsingCvModal.Heading')}
        >
          <span>
            <span>
              {t('FieldWidgets.CvParsingButton.ParsingCvModal.ErrorMessage')}
            </span>
          </span>
        </SweetAlert>
      ) : null}
    </div>
  );
}

CvParsingButton.defaultProps = {
  multiple: false
};

CvParsingButton.propTypes = {
  formData: PropTypes.object,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  parseCv: PropTypes.func,
  popError: PropTypes.func,
  setCvParsingResponseData: PropTypes.func,
  uiSchema: PropTypes.object
};
