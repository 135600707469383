import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './ErrorPage.scss'

const ErrorPage = (props) => {
  const { t } = useTranslation()

  return (
    <div className={styles.errorPageContainer}>
      <div className='well well-lg text-center no-border'>
        <div className='img-circle svg-wrap'>
          <svg
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            x='0px'
            y='0px'
            width='516px'
            height='516px'
            viewBox='0 0 516 516'
          >
            <rect x='248' y='392' width='19' height='19' />
            <path d='M457.088,208.462c-9.562-47.812-51.638-84.15-103.275-84.15c-17.213,0-32.513,3.825-45.9,11.475c-22.949-42.075-66.937-68.85-116.662-68.85c-74.587,0-133.875,59.288-133.875,133.875c0,1.913,0,5.737,0,7.65C22.95,223.763,0,256.275,0,296.438c0,53.55,42.075,95.625,95.625,95.625H168.3l-34.425,57.375H382.5l-34.425-57.375h72.675c53.55,0,95.625-42.075,95.625-95.625C516.375,256.275,491.513,221.85,457.088,208.462z M348.075,430.312H166.388l91.8-153L348.075,430.312z M420.75,372.938H336.6l-78.412-133.875l-78.413,133.875h-84.15c-42.075,0-76.5-34.425-76.5-76.5c0-36.338,24.862-66.938,59.288-74.588C76.5,214.2,76.5,208.462,76.5,200.812c0-63.112,51.638-114.75,114.75-114.75c49.725,0,91.8,32.513,109.013,76.5c15.3-11.475,34.425-19.125,53.55-19.125c45.9,0,82.237,34.425,86.062,78.412c32.513,7.65,57.375,38.25,57.375,74.588C497.25,338.513,462.825,372.938,420.75,372.938z' />
            <rect x='248' y='315' width='19' height='57' />
          </svg>
        </div>
        <h1>
          <span className='text-danger'>
            {t('Error.' + (props.status ? props.status : 'Common') + '.Title')}
          </span>
        </h1>
        <div className='clearfix text-center'>
          {t(
            'Error.' + (props.status ? t(props.status) : 'Common') + '.Message'
          )}
          <p>
            {t('Error.Status.Code', {
              code: props.status ? t(props.status) : 404
            })}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
