import React, { useEffect, useState } from 'react';
import objectPath from 'object-path';
import update from 'immutability-helper';
import deepmerge from 'deepmerge';
import { getUiOptions } from 'react-jsonschema-form/lib/utils';
import { Utils } from 'react-jsonschemapath-form';
import { useTranslation } from 'react-i18next';

const EmailConfirmEmailField = (props) => {
  const { t } = useTranslation();

  const emailValue =
    objectPath.get(props.formData, props.uiSchema.values[0].key) ?? '';
  const confirmEmailValue =
    objectPath.get(props.formData, props.uiSchema.values[1].key) ?? '';
  const [state, setInitialState] = useState({
    email: {
      key: props.uiSchema.values[0].key,
      value: emailValue,
      errors: []
    },
    confirmEmail: {
      key: props.uiSchema.values[1].key,
      value:
        emailValue !== undefined && confirmEmailValue === undefined
          ? emailValue
          : confirmEmailValue,
      errors: []
    },
    options: getUiOptions(props.uiSchema.schema)
  });

  useEffect(() => {
    const { readonly } = Utils.resolvePathSettings(props);

    if (readonly || props.readonly || state.options.readonly) {
      // Readonly so keep the state synced
      const newState = update(
        state,
        deepmerge.all([
          Utils.getPatch('email.value', state.email.value),
          Utils.getPatch('confirmEmail.value', state.confirmEmail.value)
        ])
      );
      setState(newState);
    }
  }, []);

  const setState = (options, callback = false) => {
    setInitialState({
      ...state,
      ...options
    });
    if (callback) {
      callback();
    }
  };

  const getValue = (value) => {
    if (typeof value === 'undefined' || value === '') {
      return;
    }
    return value;
  };

  const isInValid = (email, confirmEmail) => {
    var inValid =
      (typeof email === 'undefined' && typeof confirmEmail !== 'undefined') ||
      (typeof email !== 'undefined' && typeof confirmEmail === 'undefined') ||
      (typeof email !== 'undefined' &&
        typeof confirmEmail !== 'undefined' &&
        email !== confirmEmail);
    return inValid;
  };

  const onChangeEmail = (value) => {
    const confirmEmail = state.confirmEmail.value;
    const email = getValue(value);
    const errors = isInValid(email, confirmEmail)
      ? [t('Form.Errors.ConfirmEmailInvalid')]
      : [];
    const newState = update(
      state,
      deepmerge.all([
        Utils.getPatch('email.value', email),
        Utils.getPatch('confirmEmail.errors', errors)
      ])
    );

    setState(newState);
    onChange(email, confirmEmail);
  };

  const onChangeConfirmEmail = (value) => {
    const email = state?.email?.value;
    const confirmEmail = getValue(value);
    const errors = isInValid(email, confirmEmail)
      ? [t('Form.Errors.ConfirmEmailInvalid')]
      : [];
    const newState = update(
      state,
      deepmerge.all([
        Utils.getPatch('confirmEmail.value', confirmEmail),
        Utils.getPatch('confirmEmail.errors', errors)
      ])
    );

    setState(newState);
    onChange(email, confirmEmail);
  };

  const onChange = (email, confirmEmail) => {
    props.onChange(
      deepmerge.all([
        Utils.getPatch(state.email.key, email),
        Utils.getPatch(state.confirmEmail.key, confirmEmail)
      ])
    );
  };

  const { options } = state;
  const { pathDisabled, pathReadonly } = Utils.resolvePathSettings(props);
  const disabled = pathDisabled || props.disabled || options.disabled;
  const readonly = pathReadonly || props.readonly || options.readonly;

  const emailErrors = Utils.getErrorSchemaPath(props, state.email.key).__errors;
  const confirmEmailErrors = state.confirmEmail.errors.length
    ? state.confirmEmail.errors
        .concat(
          Utils.getErrorSchemaPath(props, state.confirmEmail.key).__errors
        )
        .filter(function (n) {
          return n !== undefined;
        })
    : Utils.getErrorSchemaPath(props, state.confirmEmail.key).__errors;

  return (
    <div style={{ overflow: 'hidden' }}>
      <div
        className={
          'field ' +
          'col-xs-6 ' +
          (emailErrors || state.confirmEmail.value !== state.email.value
            ? 'has-error'
            : '') +
          ' ' +
          (readonly ? 'readonly' : '')
        }
      >
        <label className='control-label'>
          {t(state.email.key)}
          {Utils.isRequired(props, state.email.key) ? (
            <span className='req'> *</span>
          ) : null}
        </label>
        <input
          type='text'
          className='form-control'
          value={state.email.value}
          onChange={(e) => onChangeEmail(e.target.value)}
          disabled={disabled}
          readOnly={readonly}
        />
        {Utils.errorList(emailErrors)}
      </div>
      <div className='col-xs-0 hidden-xs'>
        <label className='control-label'>&nbsp;</label>
      </div>
      <div
        className={
          'field ' +
          'col-xs-6 ' +
          (confirmEmailErrors || state.confirmEmail.value !== state.email.value
            ? 'has-error'
            : '') +
          ' ' +
          (readonly ? 'readonly' : '')
        }
      >
        <label className='control-label'>
          {t(state.confirmEmail.key)}
          {Utils.isRequired(props, state.confirmEmail.key) ? (
            <span className='req'> *</span>
          ) : null}
        </label>
        <input
          type='text'
          className='form-control'
          value={state.confirmEmail.value}
          onChange={(e) => onChangeConfirmEmail(e.target.value)}
          onBlur={(e) => onChangeConfirmEmail(e.target.value)}
          disabled={disabled}
          readOnly={readonly}
        />
        {Utils.errorList(confirmEmailErrors)}
      </div>
    </div>
  );
};

export default EmailConfirmEmailField;
