import React, { useEffect, useState } from 'react'
import { forOwn } from 'lodash'

const QuestionnaireView = (props) => {
  const [state, setState] = useState({})
  // For initial render or componentDidMount
  useEffect(() => {
    initialise(props)
  }, [props])

  const initialise = (props) => {
    const { questionnaireModel, questionnaireAnswerModel, showScoring } = props
    const questionnaireAnswers = questionnaireAnswerModel || {}
    const value = []

    if (questionnaireModel?.model && questionnaireModel?.model?.pages) {
      questionnaireModel.model.pages.map((p) => {
        p.elements = p.questions || p.elements
        if (p.elements && p.elements.length) {
          p.elements.map((q) => {
            const newQ = {
              id: q.id,
              name: q.name,
              section: q.section,
              title: q.section ? q.html : q.title,
              text: q.text,
              type: q.type,
              answers: [
                {
                  title:
                    questionnaireAnswers[q.name] ||
                    (showScoring ? '[ ' + q.type + ' ]' : ''),
                  selected: true
                }
              ]
            }
            const origQ = questionnaireModel.scoring
              ? questionnaireModel.scoring.filter((s) => s.id === q.name)[0] ||
                newQ
              : newQ
            if (origQ.choices) {
              origQ.answers = origQ.choices
              delete origQ.choices
            }
            if (q.choices) {
              newQ.answers = []
              q.choices.map((c) => {
                const newChoice = {
                  id: c.value,
                  title: c.text,
                  selected:
                    questionnaireAnswers[q.name] &&
                    questionnaireAnswers[q.name].indexOf(c.value) > -1
                }
                newQ.answers.push({
                  ...(origQ.answers.filter((sc) => sc.id === c.value)[0] || {}),
                  ...newChoice
                })
              })

              if (
                q.hasOther &&
                questionnaireAnswers[q.name] &&
                (questionnaireAnswers[q.name] === 'other' ||
                  questionnaireAnswers[q.name].indexOf('other') > -1)
              ) {
                newQ.answers.push({
                  id: q.name,
                  title: 'Other: ' + questionnaireAnswers[`${q.name}-Comment`],
                  selected: true
                })
              }
            }
            if (q.items) {
              newQ.answers = []
              forOwn(q.items, function (value, key) {
                const newChoice = {
                  title: value.name,
                  selected:
                    questionnaireAnswers[q.name] &&
                    questionnaireAnswers[q.name].indexOf(value) > -1
                }
                newQ.answers.push({
                  ...newChoice
                })
              })
            }
            value.push(newQ)
          })
        }
      })
    }

    const stateData = {
      ...state,
      answered: questionnaireAnswerModel,
      value
    }
    setState(stateData)
  }

  const showAnswers = (answers, answered, showScoring) => {
    if (answers && answers.length > 0) {
      return answers.map((x, j) => {
        return !answered || x.selected ? (
          <div className='row' key={j}>
            {!showScoring ? (
              <div className='col-xs-12'>
                <p>
                  {x.title && x.title.length && x.title[0].name
                    ? x.title[0].name
                    : x.title}
                </p>
              </div>
            ) : (
              <div>
                <div className='col-xs-6 col-md-6'>
                  <p>
                    {x.title && x.title.length && x.title[0].name
                      ? x.title[0].name
                      : x.title}
                  </p>
                </div>
                <div className='col-xs-4 col-md-2'>
                  <p>{x.action ? x.action : null}</p>
                </div>
                <div className='col-xs-1 col-md-1'>
                  <p>
                    <span className='badge badge-primary'>
                      {x.score ? x.score : null}
                    </span>
                  </p>
                </div>
                <div className='col-xs-12 col-md-3'>
                  <p>{x.tags && x.tags.length ? x.tags.toString() : ''}</p>
                </div>
              </div>
            )}
          </div>
        ) : null
      })
    } else {
      return null
    }
  }

  const { value, answered } = state
  const { showScoring } = props

  return (
    <div>
      {value &&
        value.map((q, i) => (
          <div key={i} className='question'>
            {q.section ? (
              <div>
                <h3>{q.title ? q.title : null}</h3>
                {q.text ? <p>{q.text}</p> : null}
              </div>
            ) : (
              <div className='clearfix'>
                <div className='row'>
                  <div className={showScoring ? 'col-sm-12' : 'col-sm-4'}>
                    <h5>
                      {q.title && q.text ? q.text : null}{' '}
                      {q.title ? q.title : null}
                    </h5>
                  </div>
                  <div
                    className={
                      'answers ' + (showScoring ? 'col-sm-12' : 'col-sm-8')
                    }
                  >
                    {showAnswers(q.answers, answered, showScoring)}
                  </div>
                </div>
              </div>
            )}
            <hr />
          </div>
        ))}
    </div>
  )
}

export default QuestionnaireView
