import CvParsingButtonComp from './components/form/fields/CvParsingButton';
import EmailStatusIconComp from './components/form/noteForm/EmailStatusIcon';
import DocumentUrlComp from './components/common/DocumentUrl';
import NoteFormComp from './components/form/noteForm/NoteForm';
import PhoneFieldComp from './components/form/fields/PhoneField';
import ScoreModalComp from './components/common/ScoreModal';
import ModalButtonComp from './components/common/ModalButton';
import MediaChooserComp from './components/form/fields/MediaChooser';
import ModalButtonHOCComp from './components/form/fields/ModalButtonHOC';
import QJumpersFormComp from './components/form/QJumpersForm';
import IntlFieldTemplate from './components/form/IntlFieldTemplate';
import ErrorPageComp from './containers/Errors/ErrorPage';
import CheckboxComp from './components/common/Checkbox';
import LoadingComp from './components/common/Loading';
import EmptyListComp from './components/common/EmptyList';
import NoteListComp from './components/common/NoteList';
import EmailDetailComp from './components/common/EmailDetail';
import SelectLocationComp from './components/form/fields/SelectLocation';
import AddressComp from './components/form/fields/Address';

import { customWidgets } from './components/form/widgets';
import { customFields } from './components/form/fields';
import { createSectionWidget } from './components/surveyjs/surveyjsHelpers';
import QuestionnaireViewComp from './components/surveyjs/QuestionnaireView';
import {
  alphaSort,
  arrayUnique,
  arrayOfKeys,
  arrayOfObjectsToArrayOfIds,
  enumArraysToSelectDataSourceUtil
} from './utils/arrayUtils';
import {
  formattedDate,
  formattedTime,
  isEditableInterviewDate,
  createDateFromUTC,
  convertDateString
} from './utils/datetime';
import {
  createEnumDefinition,
  getItemByKeys,
  getMergeSchema,
  createEnumItemsDefinition,
  disabledAllFields
} from './components/form/utils';
import { guid } from './utils/guid';
import Toaster from './components/common/ToastHandler';
import NavigationPromptModal from './components/common/NavigationPrompt';
import LocationInputComp from './components/form/fields/LocationInput';
import FileUploadComp from './components/form/fields/FileUpload';

export const CvParsingButton = CvParsingButtonComp;
export const EmailStatusIcon = EmailStatusIconComp;
export const DocumentUrl = DocumentUrlComp;
export const NoteForm = NoteFormComp;
export const PhoneField = PhoneFieldComp;
export const ScoreModal = ScoreModalComp;
export const ModalButton = ModalButtonComp;
export const MediaChooser = MediaChooserComp;
export const ModalButtonHOC = ModalButtonHOCComp;
export const NavigationPrompt = NavigationPromptModal;
export const QJumpersForm = QJumpersFormComp;
export const QuestionnaireView = QuestionnaireViewComp;
export const Loading = LoadingComp;
export const ErrorPage = ErrorPageComp;

export const Checkbox = CheckboxComp;
export const ToastHandler = Toaster;
export const EmptyList = EmptyListComp;
export const NoteList = NoteListComp;
export const EmailDetail = EmailDetailComp;
export const SelectLocation = SelectLocationComp;
export const Address = AddressComp;
export const LocationInput = LocationInputComp;
export const createSectionWidgetComp = createSectionWidget;
export const customWidgetsComp = customWidgets;
export const customFieldsComp = customFields;
export const IntlFieldTemplateComp = IntlFieldTemplate;
export const alphaSortUtil = alphaSort;
export const arrayUniqueUtil = arrayUnique;
export const arrayOfKeysUtil = arrayOfKeys;

export const formattedDateUtil = formattedDate;
export const formattedTimeUtil = formattedTime;
export const isEditableInterviewDateUtil = isEditableInterviewDate;
export const createDateFromUTCUtil = createDateFromUTC;
export const convertDateStringUtil = convertDateString;

export const arrayOfObjectsToArrayOfIdsUtil = arrayOfObjectsToArrayOfIds;
export const createEnumDefinitionUtil = createEnumDefinition;
export const createEnumItemsDefinitionUtil = createEnumItemsDefinition;
export const disabledAllFieldsUtil = disabledAllFields;
export const getItemByKeysUtil = getItemByKeys;
export const getMergeSchemaUtil = getMergeSchema;
export const guidUtil = guid;
export const enumArraysToSelectDataSource = enumArraysToSelectDataSourceUtil;
export const FileUpload = FileUploadComp;
