import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

const ModalContainer = (props) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (props.visible) {
      setVisible(props.visible)
    }
  }, [])

  // for componentWillReceiveProps
  useEffect(() => {
    if (props.visible !== visible) {
      setVisible(props.visible)
    }
  }, [props.visible])

  const hide = () => {
    const { onHide } = props
    if (onHide) {
      onHide()
    }
    setVisible(false)
  }

  const {
    children,
    title,
    className,
    closeButton,
    backdrop,
    keyboard,
    dialogClassName
  } = props
  return (
    <span>
      {children ? (
        <Modal
          show={visible}
          onHide={hide}
          className={className}
          backdrop={backdrop || 'static'}
          keyboard={keyboard}
          dialogClassName={dialogClassName}
        >
          <Modal.Header className='brand-primary' closeButton={closeButton}>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{visible ? children : null}</Modal.Body>
        </Modal>
      ) : null}
    </span>
  )
}

export default ModalContainer
