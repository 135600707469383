import React, { useCallback, useState } from 'react';
import Immutable from 'immutable';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import EmailStatusIcon from './EmailStatusIcon';
import EmailDetail from '../../common/EmailDetail';
import NoteList from '../../common/NoteList';
import QJumpersForm from '../QJumpersForm';
import { createEnumDefinition } from '../utils';
import ScoreModal from '../../common/ScoreModal';
import { ConstValues } from '../../../utils/constValues';
import styles from './Notes.scss';

export default function NoteForm(props) {
  const {
    customFields,
    isAddNoteEnabled,
    getDocumentContent,
    hasCommunicationRights,
    hasPanelMemberViewRights,
    initialValues,
    noteTypes,
    organisationId,
    popToast,
    schemaData,
    uiSchema,
    documentCategories,
    isEditDeleteNoteEnabled,
  } = props;

  const { t } = useTranslation();

  const [document, setDocument] = useState();
  const [schema, setSchema] = useState(schemaData);
  const [formData, setFormData] = useState(initialValues);
  const [notes, setNotes] = useState([]);
  const [title, setTitle] = useState('');
  const [showAddNote, setShowAddNote] = useState(false);
  const [email, setEmail] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [note, setNote] = useState();
  const [showScoreModal, setShowScoreModal] = useState(false);
  const [jobWeightage, setJobWeightage] = useState();
  const [scorePercentage, setScorePercentage] = useState();
  const [noteId, setNoteId] = useState();

  const initialise = (notes) => {
    // Making Employment Document Note Type Default to Hiring Manager.
    const filteredNoteTypes = noteTypes.filter((n) => {
      return ConstValues.NoteFormConstants.HIRINGMANAGER_ACCESS_FLAG_CONST in
        props
        ? n.name ===
            ConstValues.NoteFormConstants.HIRINGMANAGER_ACCESSTYPENOTES_CONST
        : n.userSelectable;
    });
    const noteTypesByEntity = getNoteTypesByEntity(filteredNoteTypes);
    const noteTypesSchema = createEnumDefinition(
      'noteTypes',
      noteTypesByEntity
    );
    const documentCategoriesSchema = createEnumDefinition(
      'documentCategories',
      documentCategories,
      true,
      "title"
    );
    
    const newSchema = Immutable.fromJS(schema)
      .set('definitions', {
        ...schema.definitions,
        ...noteTypesSchema,
        ...documentCategoriesSchema
      })
      .toJS();

    let formData = initialValues;
    if (noteTypesByEntity.length) {
      formData = Immutable.fromJS(formData)
        .set('note', {
          ...initialValues.note,
          noteTypeId: noteTypesByEntity[0].id
        })
        .toJS();
    }
    setTitle(getRelatedObjectTitle());
    setSchema(newSchema);
    setFormData(formData);
    setNotes(notes);
    setShowModal(true);
  };

  const getNoteTypesByEntity = (entityNoteTypes) => {
    return initialValues.note.entityType === 'job' ||
      initialValues.note.entityType === 'jobrequisition'
      ? entityNoteTypes.filter((t) => t.name === 'General')
      : entityNoteTypes;
  };

  const getRelatedObjectTitle = () => {
    var relatedObjectTitle;
    const { relatedObject } = props;

    if (relatedObject?.candidate) {
      relatedObjectTitle =
        relatedObject.candidate.firstName +
        ' ' +
        relatedObject.candidate.lastName;
    } else if (relatedObject?.title) {
      relatedObjectTitle = relatedObject.title;
    } else if (relatedObject?.name) {
      relatedObjectTitle = relatedObject.name;
    } else if (relatedObject?.firstName) {
      relatedObjectTitle =
        relatedObject.firstName + ' ' + relatedObject.lastName;
    }
    return (
      <span>
        {t('Application.Notes.Modal.Heading')} - {relatedObjectTitle}
      </span>
    );
  };

  const onFormDataChange = (formData) => {
    if (formData.note.document == null || typeof formData.note.document === undefined) {
      delete formData.note.document;
    }
  };

  const onAddNoteFormSubmit = (formData) => {
    const { addNote, editNote, candidateId, jobId, setNoteTypeId, submitDocument } =
      props;

    if (setNoteTypeId) setNoteTypeId(formData.note);
    if (submitDocument) submitDocument(formData.note.document);

    formData.note.entityType = initialValues.note.entityType;
    formData.note.entityId = initialValues.note.entityId;

    if (isEmpty(formData.note.document)) {
      formData.note.document = null;
    }

    if (noteId && formData.note.id && formData.note.id !== "00000000-0000-0000-0000-000000000000" ) {
      if (formData.note.document === null) {
        delete formData.note.document;
      }

    return editNote(formData.note, candidateId, jobId).then((result) => {
      if (result?.data) {
        popToast([
          {
            type: 'success',
            title: t('Note.Modal.SaveMessage')
          }
        ]);
        loadNotesOnRelatedObjectType();
      } else {
          popToast([]);
        }
        setShowAddNote(false);
      });
    }
    else {
     return addNote(formData.note, candidateId, jobId).then((result) => {
        if (result?.data) {
          popToast([
            {
              type: 'success',
              title: t('Note.Modal.SaveMessage')
            }
          ]);
          loadNotesOnRelatedObjectType();
        } else {
          popToast([]);
        }
        setShowAddNote(false);
      });
    }
  };

  const onBackToList = () => {
    setShowAddNote(false);
    setEmail(null);
  };

  const onGetDocumentUrl = () => {
    const { getDocumentUrl } = props;

    if (getDocumentUrl)
      getDocumentUrl(id).then((result) => {
        setDocument(result?.data);
      });
  };

  const onModalClose = () => {
    setShowModal(false);
    setShowAddNote(false);
    setEmail(null);
  };

  const onScoreModalClose = () => {
    setShowScoreModal(false);
  };

  const onViewMore = (noteData) => {
    setNote(noteData);

    if (!noteData.id) return;

    const { loadViewMoreNote } = props;

    if (loadViewMoreNote)
      loadViewMoreNote(noteData.id).then((result) => {
        if (result?.data) {
          setJobWeightage(JSON.parse(result.data.jobweightage));
          setScorePercentage(JSON.parse(result.data.scorepercentage));
          setShowScoreModal(true);
        } else {
          popToast([]);
        }
      });
  };

  const loadNotesOnRelatedObjectType = () => {
    const isTalentPullAISearchCandidate =
      props.relatedObject?.candidate && props.relatedObject?.profileId;
    const isAISearchedCandidate = props.relatedObject?.diversity;
    const {
      callFrom,
      loadNotes,
      loadSearchEmailNotes,
      loadTalentPoolEmailNotes
    } = props;
    if (isTalentPullAISearchCandidate && callFrom) {
      loadTalentPoolEmailNotes(props.relatedObject.id).then((notes) => {
        if (notes?.data) {
          initialise(
            notes?.data.filter((d) => d.note !== null).map((s) => s.note)
          );
        } else {
          popToast([]);
        }
      });
    } else if (isAISearchedCandidate && callFrom) {
      loadSearchEmailNotes(props.relatedObject.id).then((notes) => {
        if (notes?.data) {
          initialise(
            notes?.data.filter((d) => d.note !== null).map((s) => s.note)
          );
        } else {
          popToast([]);
        }
      });
    } else {
      loadNotes(initialValues.note).then((notes) => {
        if (notes?.data) {
          initialise(notes.data);
        } else {
          popToast([]);
        }
      });
    }
  };

  const onModalOpen = () => {
    loadNotesOnRelatedObjectType();
  };

  const onAddNote = () => {
    initialise(notes);
    setShowAddNote(true);
  };
  
  const getCurrentNote = (noteId) => {
    const note = notes.find(n => n.id === noteId);

    const newFormData = {
      note: {
        ...initialValues.note,
        ...note
      }
    };

    if (isEmpty(newFormData.note.document)) {
      newFormData.note.document = null;
    }
    return newFormData;
  };

  const onEditNote = (noteId) => {
    const newFormData = getCurrentNote(noteId);

    setFormData(newFormData);
    setShowAddNote(true);
    setNoteId(noteId);
  }

  const onDeleteNote = (noteId) => {
    const { popError } = props;

    let newFormData = getCurrentNote(noteId);
    newFormData.note.isDeleted = true;
    
    popError([{
      type: 'warning',
      title: t('Note.Delete.Modal.Title'),
      message: t('Note.Delete.Modal.Confirm.Message'),
      confirmBtnText: t('Action.Delete'),
      cancelBtnText: t('Action.Cancel'),
      onConfirm: () => deleteNote(newFormData.note),
      onCancel: () => {onBackToList},
      showCancel: true
    }]);
  };

  const deleteNote = (note)  => {
    const { editNote, candidateId, jobId } = props;
    return editNote(note, candidateId, jobId).then((result) => {
      if (result?.data) {
        popToast([
          {
            type: 'success',
            title: t('Note.Modal.SaveMessage')
          }
        ]);
        loadNotesOnRelatedObjectType();
      } else {
        popToast([]);
      }
      setShowAddNote(false);
    });
  };

  const onViewEmail = useCallback(
    (id) => {
      const { loadEmailByNote } = props;

      if (loadEmailByNote)
        loadEmailByNote(id).then((result) => {
          if (result?.data) {
            setEmail(result.data);
          } else {
            popToast([]);
          }
        });
    },
    [props.loadEmailByNote]
  );

  if (!noteTypes) return null;

  /* eslint-disable no-useless-escape */
  const scoreValue = parseFloat(note?.noteText.match(/[\d\.]+/));
  /* eslint-enable no-useless-escape */

  return (
    <div className={styles.notes}>
      {props.showNotesButton ? (
        <Button data-testid='notesTest' bsSize='small' onClick={onModalOpen}>
          Notes
        </Button>
      ) : (
        ''
      )}
      {props.emailStatus > 0 && (
        <span onClick={onModalOpen}>
          <EmailStatusIcon emailStatus={props.emailStatus} />
        </span>
      )}
      {showScoreModal ? (
        <ScoreModal
          scorePercentage={scorePercentage}
          jobWeightage={jobWeightage}
          visible={showScoreModal}
          title={t('AI.Scoring.Title')}
          relatedObject={note}
          value={scoreValue}
          onHide={() => onScoreModalClose()}
          heading={title}
        />
      ) : (
        <Modal show={showModal} onHide={onModalClose}>
          <Modal.Header closeButton className='brand-primary'>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.modalbody}>
            <div className={styles.notes}>
              {showAddNote ? (
                <div className={styles.noteForm}>
                  <QJumpersForm
                    schema={schema}
                    uiSchema={uiSchema}
                    initialValues={formData}
                    onChange={onFormDataChange}
                    onSubmit={onAddNoteFormSubmit}
                    onCancel={onBackToList}
                    customFields={customFields}
                  />
                </div>
              ) : email ? (
                <EmailDetail
                  document={document}
                  email={email}
                  getDocumentUrl={onGetDocumentUrl}
                >
                  <Button
                    bsClass='action'
                    className='btn btn-border'
                    type='button'
                    onClick={onBackToList}
                  >
                    {t('Action.Cancel')}
                  </Button>
                </EmailDetail>
              ) : (
                <NoteList
                  t={t}
                  notes={notes}
                  noteTypes={noteTypes}
                  isAddNoteEnabled={isAddNoteEnabled}
                  onAddNote={onAddNote}
                  onEditNote={onEditNote}
                  onDeleteNote={onDeleteNote}
                  onViewMore={onViewMore}
                  onViewEmail={onViewEmail}
                  hasCommunicationRights={
                    hasCommunicationRights || hasPanelMemberViewRights
                  }
                  organisationId={organisationId}
                  getDocumentContent={getDocumentContent}
                  isEditDeleteNoteEnabled={isEditDeleteNoteEnabled}
                />
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

NoteForm.propTypes = {
  addNote: PropTypes.func,
  editNote: PropTypes.func,
  callFrom: PropTypes.any,
  customFields: PropTypes.object,
  emailStatus: PropTypes.number,
  getDocumentContent: PropTypes.func,
  getDocumentUrl: PropTypes.func,
  hasCommunicationRights: PropTypes.bool,
  hasPanelMemberViewRights: PropTypes.bool,
  initialValues: PropTypes.object,
  isAddNoteEnabled: PropTypes.bool,
  isEditDeleteNoteEnabled: PropTypes.bool,
  loadEmailByNote: PropTypes.func,
  loadNotes: PropTypes.func,
  loadSearchEmailNotes: PropTypes.func,
  loadTalentPoolEmailNotes: PropTypes.func,
  noteTypes: PropTypes.arrayOf(PropTypes.object),
  onViewMore: PropTypes.func,
  organisationId: PropTypes.string,
  popToast: PropTypes.func,
  relatedObject: PropTypes.object,
  schemaData: PropTypes.object,
  setNoteTypeId: PropTypes.func,
  showNotesButton: PropTypes.bool,
  submitDocument: PropTypes.func,
  uiSchema: PropTypes.object,
  popError: PropTypes.func,
};
