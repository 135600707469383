import React from 'react'

export function createSectionWidget(Survey) {
  // Add format property into html question
  Survey.JsonObject.metaData.addProperty('html', {
    name: 'section',
    default: false
  })
  Survey.JsonObject.metaData.addProperty('html', {
    name: 'text',
    default: ''
  })
  // Add Section Widget
  var sectionWidget = {
    name: 'section',
    htmlTemplate:
      "<hr class='section-hr'><h3 class='section-title' data-bind='html:question.html'></h3><p class='section-text' data-bind='html:question.text'></p>",
    isFit: function (question) {
      return question.section
    },
    render: function (question) {
      return (
        <div className='section'>
          <hr className='section-hr' />
          {question.html ? (
            <h3 className='section-title'>{question.html}</h3>
          ) : null}
          {question.text ? (
            <p className='section-text'>{question.text}</p>
          ) : null}
        </div>
      )
    }
  }
  Survey.CustomWidgetCollection.Instance.addCustomWidget(sectionWidget)

  return Survey
}
