import React, { useState, useEffect } from 'react';
import * as Survey from 'survey-react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import QuestionnaireView from '../../surveyjs/QuestionnaireView';
import { createSectionWidget } from '../../surveyjs/surveyjsHelpers';
import { surveyStrings } from '../../../helpers/surveyStrings';
import { clone, isEqual } from 'lodash';
import styles from './SurveyWidget.scss';
import { StepContext } from 'react-jsonschemapath-form';

const SurveyWidget = (props) => {
  const { t } = useTranslation();
  const [state, setSurveyState] = useState({});

  useEffect(() => {
    setState(initialise());
  }, [props.value]);

  const setState = (options) => {
    setSurveyState({
      ...state,
      ...options
    });
  };

  const isExistingSurvey = (existingQuestionHashes, newQuestionHashes) => {
    const existingNames = existingQuestionHashes.names;
    const newNames = newQuestionHashes.names;

    // If either are null return false
    if (!existingNames || !newNames) {
      return false;
    }

    // Get the keys (question names) from both objects
    const existingKeys = Object.keys(existingNames);
    const newKeys = Object.keys(newNames);

    // Check if the keys (question names) are the same
    const keysMatch = isEqual(existingKeys, newKeys);

    // Check if the IDs for corresponding questions match
    const idsMatch = existingKeys.every((key) => {
      const existingName = existingNames[key];
      const newName = newNames[key];

      return existingName && newName && existingName[0].ID === newName[0].ID;
    });

    // Check if both keys and IDs match
    return keysMatch && idsMatch;
  };

  const initialise = () => {
    const { options, value, readonly } = props;

    Survey.defaultBootstrapCss.navigationButton = 'btn btn-primary';
    Survey.Survey.cssType = 'bootstrap';
    Survey.surveyLocalization.locales.qj = surveyStrings;
    Survey.surveyLocalization.currentLocale = 'qj';
    Survey.JsonObject.metaData.addProperty('question', { name: 'ID:string' });
    createSectionWidget(Survey);

    const stateInit = {
      value,
      showRequired: state.showRequired || false
    };
    const survey = new Survey.Model(options.survey);
    if (
      state.survey &&
      isExistingSurvey(state.survey.questionHashes, survey.questionHashes)
    ) {
      stateInit.survey = state.survey;
    } else {
      stateInit.survey = survey;
    }

    stateInit.survey.showCompletedPage = false;
    stateInit.survey.clearInvisibleValues = 'onHidden';
    stateInit.survey.focusOnFirstError = false;
    stateInit.survey.focusFirstQuestionAutomatic = true;
    stateInit.survey.onUploadFiles.add((survey, options) => {
      // upload documents
    });
    stateInit.survey.onDownloadFile.add((survey, options) => {
      // download documents
    });

    if (value) {
      stateInit.survey.data = JSON.parse(value).model;
    }

    if (readonly) {
      stateInit.survey.mode = 'display';
    }

    stateInit.canEdit = false;

    return stateInit;
  };

  const updateValues = (value) => {
    const { onChange } = props;
    const newProps = clone(props);

    var scoring = modifySurveyResults(value);
    newProps.value = JSON.stringify(processAnswer(value.data, scoring));

    scoring.filter((x) => {
      if (x.required === true && x.answers.length === 0) {
        return x;
      } else {
        newProps.showRequired = false;
      }
    });

    setState(newProps);

    if (onChange) {
      onChange(newProps.value);
    }
  };

  const onComplete = (value) => {
    const { jumpToStep, currentStep = 0 } = StepContext;

    updateValues(value);

    setState({
      showRequired: false
    });
    if (jumpToStep) {
      jumpToStep(currentStep + 1);
    }
  };

  const modifySurveyResults = (survey) => {
    var resultData = [];
    for (var key in survey.data) {
      var question = survey.getQuestionByValueName(key);
      if (!!question && question.getType() !== 'file') {
        var item = {
          name: question.name,
          answers:
            question.value instanceof Object
              ? question.value
              : [question.value],
          required: question.isRequired
        };

        if (question.ID !== undefined) {
          item.id = question.ID;
        } else {
          item.id = question.name;
        }
        resultData.push(item);
      } else if (!!question && question.getType() === 'file') {
        var fileItem = { answers: ['file'], required: question.isRequired };
        resultData.push(fileItem);
      }
    }
    return resultData;
  };

  const onEdit = () => {
    const { survey } = state;
    survey.mode = 'edit';
    setState({ canEdit: false, survey });
  };

  const processAnswer = (val, scoring) => {
    const { survey } = state;
    let errors = false;
    if (survey.isCurrentPageHasErrors) {
      errors = true;
    }
    const model = {
      model: val,
      scoring: scoring,
      errors: errors
    };
    return model;
  };

  const onScrollingElementToTop = (_, options) => {
    //Cancel scroll
    options.cancel = true;

    // Find survey question rows
    const divElements = document.querySelectorAll('div[id*=sq_]');
    // Find question rows with errors
    const erroredDivs = Array.from(divElements).filter((div) => {
      return div.classList.contains('has-error');
    });

    // Check if there are rows with errors
    if (erroredDivs.length > 0) {
      // Scroll to the first matching div
      erroredDivs[0].scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onValueChanged = (value) => {
    const { survey } = state;

    validateFileAcceptedTypes(value);

    updateValues(value);

    if (survey.isCurrentPageHasErrors) {
      setState({
        showRequired: true
      });
    }
  };

  const validateFileAcceptedTypes = (model) => {
    for (var key in model.data) {
      var question = model.getQuestionByValueName(key);
      if (!!question && question.getType() === 'file') {
        var acceptedFileTypes = question.acceptedTypes
          ? question.acceptedTypes.split(',').map((e) => e.trim())
          : ['.pdf', '.doc', '.docx', '.png', '.jpg, .jpeg, .tif, .tiff'];
        if (
          !new RegExp(
            '(' + acceptedFileTypes.join('|').replace(/\./g, '\\.') + ')$'
          ).test(question.value[0].name)
        ) {
          question.addError(
            new Survey.SurveyError(
              'Valid extensions are ' + question.acceptedTypes
            )
          );
          question.clearIncorrectValues();
          question.value = null;
        }
      }
    }
  };

  const { survey, canEdit, value } = state;
  const { options } = props;

  return (
    <div
      className={
        styles.surveyWidget + ' surveyjsContainer surveyjs-' + survey?.mode
      }
    >
      {survey && survey?.mode !== 'display' ? (
        <div>
          <Survey.Survey
            model={survey}
            onComplete={onComplete}
            onScrollingElementToTop={onScrollingElementToTop}
            onValueChanged={onValueChanged}
          />
        </div>
      ) : (
        <QuestionnaireView
          questionnaireModel={{ model: options.survey }}
          questionnaireAnswerModel={value ? JSON.parse(value).model : {}}
        />
      )}
      {!canEdit ? null : (
        <div className='panel-footer'>
          <Button onClick={onEdit}> {t('Action.Edit')}</Button>
        </div>
      )}
    </div>
  );
};

export default SurveyWidget;
