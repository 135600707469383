// file input returns string as value but server expects object
export function splitDataURL(fileInputData) {
  var fileObject = {}
  if (fileInputData && fileInputData.hasOwnProperty('dataURL')) {
    var strings = fileInputData.dataURL.split(/;/)
    var fileType = strings[0].replace('data:', '')
    var fileName = strings[1].replace('name=', '')
    fileObject = {
      name: fileName,
      fileType: fileType,
      size: fileInputData.hasOwnProperty('size') ? fileInputData.size : 0,
      upload: fileInputData.dataURL
    }
  }
  return fileObject
}

export function addNameToDataURL(dataURL, name) {
  return dataURL.replace('base64', `name=${name};base64`)
}

export function getFileUrl(file) {
  var url = false
  if (typeof file !== 'undefined') {
    if (typeof file.upload !== 'undefined' && file.upload.length) {
      url = file.upload
    } else if (typeof file.dataURL !== 'undefined' && file.dataURL.length) {
      url = file.dataURL
    } else if (typeof file.url !== 'undefined' && file.url.length) {
      url = file.url
    }
  }
  return url
}

export function getImageFileUrl(file) {
  const fileType = file.fileType ? file.fileType : ''
  var url = getFileUrl(file).toString()
  const indexEncoding = url.indexOf('base64,')
  url = url.substr(indexEncoding)
  url = 'data:' + fileType + ';' + url
  return url
}

export function processFile(file) {
  const { name, size, type } = file
  return new Promise((resolve) => {
    const reader = new window.FileReader()
    reader.onload = (event) => {
      resolve({
        dataURL: addNameToDataURL(event.target.result, name),
        name,
        size,
        type
      })
    }
    reader.readAsDataURL(file)
  })
}

export function processFiles(files) {
  return Promise.all([].map.call(files, processFile))
}

export function submitDocument(doc) {
  let docInnerRef = doc
  if (docInnerRef) {
    if (!docInnerRef.upload) {
      docInnerRef = null
    } else if (docInnerRef.base64String) {
      delete docInnerRef.base64String
    }
  }
}
