import ImageUpload from './ImageUpload';

import CheckboxField from './CheckboxField';
import MediaChooser from './MediaChooser';
import PhoneField from './PhoneField';
import EmailConfirmEmailField from './EmailConfirmEmailField';

export const customFields = {
  imageUpload: ImageUpload,
  checkboxField: CheckboxField,
  mediaChooser: MediaChooser,
  phoneField: PhoneField,
  emailConfirmEmailField: EmailConfirmEmailField
};
