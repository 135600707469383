import React, { useImperativeHandle, useState } from 'react'
import { Button } from 'react-bootstrap'
import ModalContainer from './ModalContainer'

const ModalButton = React.forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false)
  const {
    buttonText,
    title,
    disabled,
    children,
    onClick,
    className,
    bsStyle,
    bsSize,
    buttonClassName,
    closeButton,
    backdrop,
    keyboard,
    dialogClassName
  } = props

  useImperativeHandle(ref, () => ({
    handleCloseModal() {
      setVisible(false)
    }
  }))

  const openModal = () => {
    setVisible(true)
  }

  const closeModal = () => {
    const { onHide } = props
    setVisible(false)
    if (onHide) {
      onHide()
    }
  }

  return (
    <span ref={ref}>
      <Button
        bsStyle={bsStyle}
        bsSize={bsSize}
        className={buttonClassName}
        disabled={disabled}
        onClick={() => (onClick ? onClick(openModal) : openModal())}
      >
        {buttonText}
      </Button>
      {visible ? (
        <ModalContainer
          onHide={closeModal}
          title={title}
          visible={visible}
          className={className}
          closeButton={closeButton}
          backdrop={backdrop}
          keyboard={keyboard}
          dialogClassName={dialogClassName}
        >
          {children}
        </ModalContainer>
      ) : null}
    </span>
  )
})
export default ModalButton
