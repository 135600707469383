import React from 'react'

export default function Checkbox(props) {
  return (
    <input
      {...props}
      type='checkbox'
      className='checkboxCommon'
      ref={(input) => {
        if (input) {
          input.indeterminate = props.value
        }
      }}
    />
  )
}
