import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import objectPath from 'object-path';
import deepmerge from 'deepmerge';
import DocumentUrl from '../../common/DocumentUrl';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Utils } from 'react-jsonschemapath-form';
import styles from './FileUpload.scss';
import { getUiOptions } from 'react-jsonschema-form/lib/utils';
import { uniq } from 'lodash';

const FileUpload = (props) => {
  const keyValue = props.uiSchema.key;
  const multipleValue =
    (props.uiSchema.schema['ui:options'] &&
      props.uiSchema.schema['ui:options'].multiple) ||
    false;
  const value =
    objectPath.get(props.formData, keyValue) || (multipleValue ? [] : {});
  const values = multipleValue ? value : [value];
  const showTitlesValue =
    (props.uiSchema.schema['ui:options'] &&
      props.uiSchema.schema['ui:options'].showTitles) ||
    false;
  const maxFiles =
    (props.uiSchema.schema['ui:options'] &&
      props.uiSchema.schema['ui:options'].maxFiles) ||
    1;
  var currFilesProps = objectPath.get(props.formData, keyValue);

  const stateValue = {
    key: keyValue,
    multiple: multipleValue,
    values: {
      new: values ? values.filter((f) => f && f.upload) : null,
      current: values ? values.filter((f) => f && !f.upload && f.name) : null
    },
    showTitles: showTitlesValue,
    maxFiles: maxFiles,
    maxFilesError: null,
    currFiles: currFilesProps
  };

  const options = getUiOptions(props.uiSchema.schema);

  const { t } = useTranslation();
  const [state, setFileState] = useState(stateValue);
  const [accept] = useState(options.accept || '');

  useEffect(() => {
    if (props.readonly) {
      handleComponentWillReceiveProps(props);
    }
  }, [props]);

  const handleComponentWillReceiveProps = (nextProps) => {
    if (props.readonly) {
      // Readonly so keep the state synced
      const value =
        objectPath.get(nextProps.formData, state.key) ||
        (state.multiple ? [] : {});
      const values = state.multiple ? value : [value];

      const newState = update(
        state,
        deepmerge.all([
          Utils.getPatch(
            'values.new',
            values.filter((f) => f && f.upload)
          ),
          Utils.getPatch(
            'values.current',
            values.filter((f) => f && !f.upload && f.name)
          )
        ])
      );
      setState(newState);
    }
  };

  const setState = (options) => {
    setFileState({
      ...state,
      ...options
    });
  };

  const onChange = async (values) => {
    const { maxFiles, multiple } = state;
    if (values) {
      let modifiedValues = values;
      const key = props.uiSchema.key;
      const currValue = objectPath.get(props.formData, key);

      var errorMessage = null;
      var bTooManyFiles = false;
      if (values && values.length && multiple) {
        if (values.length <= maxFiles) {
          if (currValue.length > 0) {
            const uniqValues = uniq(currValue.concat(values));
            if (uniqValues.length > maxFiles) {
              bTooManyFiles = true;
            } else {
              modifiedValues = uniqValues;
            }
          }
        } else {
          bTooManyFiles = true;
        }
      }
      if (bTooManyFiles) {
        errorMessage = ['There is a limit of ' + maxFiles + ' files to upload'];
        setState({
          maxFilesError: errorMessage
        });
      } else {
        if (!multiple) {
          values = [values];
        }
        setState({ currFiles: modifiedValues });
        await props.onChange(Utils.getPatch(state.key, modifiedValues));
      }
    }
  };

  const onDrop = (files) => {
    const { multiple } = state;
    let newProps = { ...state };

    processFiles(files).then(async (filesInfo) => {
      const files = filesInfo.map((fileInfo) => {
        const file = {
          id: '00000000-0000-0000-0000-000000000000',
          upload: fileInfo.dataURL,
          ...splitDataURL(fileInfo.dataURL)
        };
        return file;
      });

      newProps = await update(newProps, Utils.getPatch('values.new', files));

      await setState(newProps);
      if (multiple) {
        onChange(newProps.values.new.concat(newProps.values.current));
      } else {
        onChange(newProps.values.new.concat(newProps.values.current)[0]);
      }
    });
  };

  const handleKeyDownToClick = (e) => {
    // Trigger the click event from the keyboard
    var code = e.which;
    const { disabled } = Utils.resolvePathSettings(props);
    // 13 = Return, 32 = Space
    if (code === 13 || code === 32) {
      if (e.target.click && !disabled) {
        e.target.click();
      }
    }
  };

  const handleRemoveFile = (index) => {
    const { key } = state;

    var currFiles = objectPath.get(props.formData, key);
    if (currFiles && currFiles.length) {
      currFiles.splice(index, 1);
    } else {
      currFiles = null;
    }
    handleRemoveFileNew(index, currFiles);
    props.onChange(Utils.getPatch(key, currFiles));
  };

  const handleRemoveFileNew = (index, currFiles) => {
    const { values } = state;
    const valuesNew = values.new;
    valuesNew.splice(index, 1);
    const newValues = values;
    newValues.new = valuesNew;
    setState({ values: newValues, maxFilesError: null, currFiles: currFiles });
  };

  const { multiple, showTitles, maxFilesError, currFiles } = state;
  const { disabled, readonly } = Utils.resolvePathSettings(props);
  let errors;
  let required = false;
  if (state?.key) {
    required = Utils.isRequired(props, state?.key);
  }
  if (required) {
    errors = Utils.getErrorSchemaPath(props, `${state?.key}.upload`).__errors;
  } else {
    errors = Utils.getErrorSchemaPath(props, state?.key).__errors;
  }
  let currFilesArray = currFiles;
  if (!multipleValue && currFilesArray) {
    currFilesArray = [currFilesArray];
  }

  return (
    <div>
      <div
        className={
          styles.files +
          (errors ? ' has-error' : '') +
          ' ' +
          (readonly ? ' readonly' : '')
        }
      >
        <label className='control-label'>
          {t(state.key)}
          {required ? <span className='req'> *</span> : null}
        </label>
        <div className='row'>
          {readonly || disabled ? null : (
            <div className='col-sm-3'>
              <Dropzone
                tabIndex='0'
                accept={accept}
                onKeyDown={(e) => handleKeyDownToClick(e)}
                onDrop={onDrop}
                disabled={disabled}
                multiple={multiple}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps({
                        className: styles.dropZone + ' btn btn-add'
                      })}
                    >
                      <input {...getInputProps()} />
                      <span className='qjicon-up'> </span>
                      {t('Action.UploadFile') + (multiple ? 's' : '')}
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          )}
          <div className='col-sm-9'>
            {currFilesArray && currFilesArray.length ? (
              <div className={styles.filesList}>
                {showTitles ? <h6>{t('Form.Files.NewTitle')}</h6> : null}
                {currFilesArray.map((f, index) => {
                  var file = { ...f };
                  return (
                    <div key={index}>
                      {file && file.name ? (
                        <DocumentUrl
                          getDocumentUrl={props.getDocumentUrl}
                          getDocumentContent={props.getDocumentContent}
                          pdfContent={props.pdfContent}
                          showDelete
                          file={file}
                          styles='btn btn-default btn-sm'
                          btnSize='xsmall'
                          fileAction={() => handleRemoveFile(index)}
                          index={index}
                          icon='glyphicon glyphicon-remove'
                          organisationId={props.organisationId}
                        />
                      ) : null}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>

        {Utils.errorList(errors)}
        {maxFilesError ? (
          <React.Fragment>
            <div className='clear-fix' />
            <div>
              <p />
              <ul className='error-detail bs-callout bs-callout-info'>
                <li className='text-danger'>{maxFilesError}</li>
              </ul>
            </div>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};

FileUpload.defaultProps = {
  autofocus: false,
  multiple: false
};

FileUpload.propTypes = {
  multiple: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  autofocus: PropTypes.bool
};

export default FileUpload;

function splitDataURL(document) {
  const strings = document.split(/;/);
  const fileName = strings[1].replace('name=', '');
  const base64String = strings[2].replace('base64,', '');

  return {
    name: fileName,
    base64String: base64String
  };
}

function addNameToDataURL(dataURL, name) {
  return dataURL.replace(';base64', `;name=${name};base64`);
}

function processFile(file) {
  const { name, size, type } = file;
  return new Promise((resolve) => {
    const reader = new window.FileReader();
    reader.onload = (event) => {
      resolve({
        dataURL: addNameToDataURL(event.target.result, name),
        name,
        size,
        type
      });
    };
    reader.readAsDataURL(file);
  });
}

function processFiles(files) {
  return Promise.all([].map.call(files, processFile));
}

FileUpload.contextTypes = {
  removeFile: PropTypes.func
};
