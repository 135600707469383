export function uAgent() {
  var ua = window.navigator.userAgent
  var ieRe = /(?:(MSIE) |(Trident)\/.+rv:)([\w.]+)/i // must not contain 'Opera'
  var match = ua.match(ieRe)
  if (match) {
    return true
  } else {
    return false
  }
}
