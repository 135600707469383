import React from 'react'
import { useTranslation } from 'react-i18next'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const REQUIRED_FIELD_SYMBOL = '*'

function Label(props) {
  const { t } = useTranslation()
  const { uiKey, required, id, help } = props
  return (
    <label className='control-label' htmlFor={id}>
      {t(uiKey)}
      {required ? REQUIRED_FIELD_SYMBOL : null}
      {help.props.help ? (
        <OverlayTrigger
          placement='right'
          overlay={<Tooltip id='tooltip'>{t(help.props.help)}</Tooltip>}
        >
          <i className='glyphicon glyphicon-info-sign' />
        </OverlayTrigger>
      ) : null}
    </label>
  )
}

const IntlFieldTemplate = (props) => {
  const {
    id,
    classNames,
    children,
    errors,
    description,
    hidden,
    required,
    readonly,
    displayLabel,
    uiSchema,
    help
  } = props
  if (hidden || classNames.includes('field-object')) {
    return children
  }

  let allClassNames = classNames
  if (readonly) {
    allClassNames += ' readonly'
  }

  return (
    <div className={allClassNames}>
      {(displayLabel || classNames.includes('field-array')) &&
        uiSchema.uiKey && (
          <Label
            uiKey={uiSchema.uiKey}
            required={required}
            id={id}
            help={help}
          />
        )}
      {(displayLabel || classNames.includes('field-array')) &&
      description &&
      description.props.description
        ? description
        : null}
      {children}
      {errors.props.errors ? errors : null}
    </div>
  )
}

export default IntlFieldTemplate
