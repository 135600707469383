import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ModalButton from '../../common/ModalButton';

export default function ModalButtonHOC(WrappedComponent, child) {
  return function Enhancer(props) {
    const elementsTreeRef = useRef();
    const modalButton = useRef();
    const { t } = useTranslation();
    const getElementsTree = () => elementsTreeRef;

    const closeModal = () => {
      modalButton.current.handleCloseModal();
    };

    const isComponent = (e) =>
      React.Component.prototype.isPrototypeOf(e.prototype);

    const modal = (elementsTree) => {
      const { options } = props;
      const newProps = {
        ...props,
        getElementsTree: getElementsTree,
        closeModal: closeModal
      };
      const modalButtonText = t(
        options.buttonText ? options.buttonText : 'buttonText'
      );
      const modalButtonTitle = t(options.title ? options.title : 'title');

      return (
        <div className={options.css ? options.css.wrapper : null}>
          <div
            ref={elementsTreeRef}
            className={options.css ? options.css.element : null}
          >
            {elementsTree}
          </div>
          <div className={options.css ? options.css.button : null}>
            <ModalButton
              buttonClassName={options.css ? options.css.btn : null}
              className={options.css ? options.css.modal : null}
              ref={modalButton}
              buttonText={modalButtonText}
              title={modalButtonTitle}
              closeButton={options.closeButton ? options.closeButton : false}
            >
              {React.isValidElement(child)
                ? React.cloneElement(child, newProps)
                : React.createElement(child, newProps)}
            </ModalButton>
          </div>
        </div>
      );
    };

    if (isComponent(WrappedComponent)) {
      return <div>{modal(<WrappedComponent {...props} />)}</div>;
    }

    const elementsTree = WrappedComponent(props);
    return <div>{modal(elementsTree)}</div>;
  };
}
