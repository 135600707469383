import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import objectPath from 'object-path';
import { Utils } from 'react-jsonschemapath-form';
import { getUiOptions } from 'react-jsonschema-form/lib/utils';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

const LocationInput = (props) => {
  const { t } = useTranslation();
  const [state, setLocationState] = useState({});
  const minLengthAutocomplete = 2;

  useEffect(() => {
    handleDidMount();
  }, []);

  const setState = (options) => {
    setLocationState({
      ...state,
      ...options
    });
  };

  const handleDidMount = () => {
    const newState = initialise(props);
    const busy = false;
    const lastSearchedTime = false;
    const init = true;
    const key = props.uiSchema.key;

    setState({
      ...newState,
      key: key,
      busy: busy,
      lastSearchedTime: lastSearchedTime,
      init: init,
      placeholder: props.placeholder
    });
  };

  const initialise = (props) => {
    const options = getUiOptions(props.uiSchema.schema) || {};

    let value = props.value;
    if (props.uiSchema.key) {
      value = objectPath.get(props.formData, props.uiSchema.key) || props.value;
    }
    const newState = {
      ...options,
      value: Array.isArray(value) ? value : null,
      busy: false,
      location: Array.isArray(value) ? value : null
    };

    return newState;
  };

  const onChange = (value) => {
    setState({
      value: value
    });
    if (props.onChange) {
      if (state.key) {
        props.onChange(Utils.getPatch(state.key, value));
      } else {
        props.onChange(value);
      }
    }
  };
  const fetchSuggestions = useDebouncedCallback((value, cb) => {
    if (value.length < minLengthAutocomplete) {
      return cb([]);
    }

    props.searchLocations(value).then((response) => {
      if (response) {
        const res = response.data;
        const desc = res;
        setState({ location: desc || [] });
        return cb(desc || []);
      }
      return cb([]);
    });
  }, 300);

  const { value, init, placeholder, key } = state;

  const errors = key ? Utils.getErrorSchemaPath(props, key) : [];

  return init ? (
    <div className='select'>
      {key ? (
        <label className='control-label'>
          {t(key)}
          {Utils.isRequired(props, key) ? (
            <span className='req'> *</span>
          ) : null}
        </label>
      ) : null}

      <AsyncSelect
        isMulti
        loadOptions={fetchSuggestions}
        cacheOptions
        getOptionValue={(option) => option.key}
        getOptionLabel={(option) => option.displayString}
        onChange={(e) => {
          onChange(e);
        }}
        placeholder={placeholder}
        isDisabled={props.disabled || props.readonly}
        value={value}
        defaultOptions={value}
        options={state.location}
        className='basic-multi-select'
        classNamePrefix='select'
      />
      {Utils.errorList(errors)}
    </div>
  ) : null;
};

LocationInput.propTypes = {
  formData: PropTypes.object,
  value: PropTypes.array,
  uiSchema: PropTypes.shape({
    'ui:placeholder': PropTypes.string,
    'ui:options': PropTypes.shape({
      minLength: PropTypes.number
    })
  })
};

export default LocationInput;
