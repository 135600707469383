import React, { useState, useEffect } from 'react'
// import { connect } from "react-redux";
// import * as notifActions from "../../redux/actions/notifs";
import SweetAlert from 'react-bootstrap-sweetalert'
import styles from './ErrorHandler.scss'

// @connect(
//   state => ({
//     errors: state.notifs.errors
//   }),
//   { ...notifActions }
// )

const ErrorHandler = (props) => {
  const [error, setError] = useState(null)
  // const [errorIdx, setErrorIdx] = useState()

  const hideAlert = (callback) => {
    // const { clearErrors } = props
    if (callback) {
      callback()
    }
    // clearErrors(errorIdx)
    setError(null)
    // setErrorIdx(0)
  }

  const getErrorMessageDisplay = (errorDetail) => {
    const errors = []
    let idx = 0
    for (var property in errorDetail) {
      if (Array.isArray(errorDetail[property])) {
        errorDetail[property].map((message, i) =>
          errors.push(<p key={idx + '_' + i}> {message} </p>)
        )
      } else if (typeof errorDetail[property] !== 'string') {
        errors.push(<p key={idx}> {JSON.stringify(errorDetail[property])} </p>)
      } else {
        errors.push(<p key={idx}> {errorDetail[property]} </p>)
      }
      idx++
    }
    return errors
  }

  useEffect(() => {
    const errorIdx = props.errors.length - 1
    // setErrorIdx(errorIdx)
    if (error === null) {
      setError(props.errors.length ? props.errors[errorIdx] : null)
    }
  }, [props.errors])

  return error ? (
    <div className={styles.sweetAlert}>
      <SweetAlert
        type={error.type}
        title={error.title}
        confirmBtnBsStyle={error.confirmBtnBsStyle || 'primary'}
        confirmBtnText={error.confirmBtnText || 'OK'}
        onConfirm={() => hideAlert(error.onConfirm)}
        showCancel={error.showCancel || false}
        cancelBtnBsStyle={error.cancelBtnBsStyle || 'default'}
        cancelBtnText={error.cancelBtnText || 'Cancel'}
        onCancel={() => hideAlert(error.onCancel)}
      >
        <span>
          <span>{error.message}</span>
          {error.detail ? (
            <small>
              <br />
              {getErrorMessageDisplay(error.detail)}
            </small>
          ) : null}
        </span>
      </SweetAlert>
    </div>
  ) : null
}

export default ErrorHandler
