export function alphaSort(array, key) {
  return array.sort(function (a, b) {
    var aValue = a[key]
    var bValue = b[key]

    if (!aValue && !bValue) {
      return 0
    }
    if (!aValue && bValue) {
      return -1
    }
    if (aValue && !bValue) {
      return 1
    }

    const nameA = aValue.toUpperCase() // ignore upper and lowercase
    const nameB = bValue.toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })
}

export function createEnumDefinition(
  key,
  arr,
  includeDefault = false,
  nameProperty = 'name',
  type = 'string',
  idProperty = 'id'
) {
  const defaultVal = includeDefault && arr.length ? arr[0].id : undefined

  return {
    [key]: {
      type,
      enum: arr.map((o) => o[idProperty]),
      enumNames: arr.map((o) => o[nameProperty]),
      default: defaultVal
    }
  }
}

export function arrayUnique(array) {
  var a = array.concat()
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) {
        a.splice(j--, 1)
      }
    }
  }

  return a
}

export function arrayOfObjectsToArrayOfIds(array) {
  var a = []
  for (var i = 0; i < array.length; ++i) {
    if (!Array.isArray(array[i]) && array[i].hasOwnProperty('id')) {
      a.push(array[i].id)
    } else {
      a.push(arrayOfObjectsToArrayOfIds(array[i]))
    }
  }
  return a
}

export function enumArraysToSelectDataSourceUtil(arrayOfIds, arrayOfNames) {
  var output = []
  for (var i = 0; i < arrayOfIds.length; i++) {
    output.push({
      id: arrayOfIds[i],
      name: arrayOfNames[i]
    })
  }

  return output
}

export function arrayOfKeys(array, key) {
  var output = []
  for (var i = 0; i < array.length; ++i) {
    if (Object.prototype.hasOwnProperty.call(array[i], key)) {
      output.push(array[i][key])
    }
  }

  return output
}
