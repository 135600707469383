import React, { useState, useEffect } from 'react';
import { Button, Glyphicon } from 'react-bootstrap';
import { downloadPdfFromBase64Data } from '../../utils/documentsHelper';
import { isMobile } from 'react-device-detect';

export default function DocumentUrl(props) {
  const [url, setUrl] = useState();
  const {
    title,
    file,
    fileAction = () => {},
    showDelete = false,
    icon = '',
    btnSize = 'small',
    downloadList = true,
    styles = ''
  } = props;

  const fileName = file.name ? file.name : 'File';

  useEffect(() => {
    if (props.document) {
      if (isMobile) {
        window.location = props.document;
      } else {
        setUrl(props.document);
      }
    }
  }, [props.document]);

  useEffect(() => {
    if (props.pdfContent) {
      downloadPdfFromBase64Data(
        props.pdfContent.pdfContent,
        file.name,
        props.pdfContent.mimeType
      );
    }
  }, [props.pdfContent]);

  const base64StringToFile = (base64string, filename) => {
    var arr = base64string.split(';');
    var mimeType = arr[0].replace('data:', '');

    // eslint-disable-next-line no-undef
    return fetch(base64string)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        // eslint-disable-next-line no-undef
        return new File([buf], filename, { type: mimeType });
      });
  };

  const handleClick = (e) => {
    e.preventDefault();
    const { file } = props;
    const fileId = props.file.id;
    const organisationId = props.organisationId;

    if (file.id === '00000000-0000-0000-0000-000000000000') {
      base64StringToFile(file.upload, file.name).then(function (file) {
        window.open(URL.createObjectURL(file), '_blank');
      });
    } else if (typeof organisationId === 'undefined' && props.getDocumentUrl) {
      props.getDocumentUrl(file.id).then((result) => {
        if (isMobile) {
          window.location = result.data;
        } else {
          setUrl(result.data);
        }
      });
    } else {
      props.getDocumentContent(fileId, organisationId).then((result) => {
        downloadPdfFromBase64Data(
          result.data.pdfContent,
          file.name,
          result.data.mimeType
        );
      });
    }
  };

  if (downloadList) {
    return (
      <span>
        <Button bsClass='action' className={styles} onClick={handleClick}>
          <span className='qjicon-paperclip'> </span> {title || fileName}
        </Button>

        {showDelete ? (
          <Button
            bsSize={btnSize}
            className={styles}
            onClick={() => fileAction(file)}
          >
            {icon === '' ? <span>Delete</span> : <Glyphicon glyph={icon} />}
          </Button>
        ) : null}
        <iframe src={url} height='0' width='0' frameBorder='0' />
      </span>
    );
  } else {
    return (
      <span>
        <span className='qjicon-paperclip'> </span> {title || fileName}
        {fileAction ? (
          <Button
            bsSize={btnSize}
            className={styles}
            onClick={() => fileAction(file)}
          >
            {icon === '' ? <span>Delete</span> : <Glyphicon glyph={icon} />}
          </Button>
        ) : null}
      </span>
    );
  }
}
