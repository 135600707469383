import React, { useEffect, useState } from 'react';
import deepmerge from 'deepmerge';
import update from 'immutability-helper';
import objectPath from 'object-path';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Utils } from 'react-jsonschemapath-form';
import { getUiOptions } from 'react-jsonschema-form/lib/utils';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styles from './PhoneField.scss';

export default function PhoneField(props) {
  const { environmentConfig, formData, includesHostName, uiSchema } = props;
  const defaultCountryCode =
    environmentConfig.host.indexOf('qjumpersjobs') > -1 ||
    environmentConfig.host.includes(includesHostName) ||
    environmentConfig.defaultCountyCode === 'NZ'
      ? 'nz'
      : 'us';
  const { t } = useTranslation();
  const values = uiSchema.values;
  const primaryNumber = objectPath.get(formData, values[0].key) ?? '';
  const secondaryNumber = objectPath.get(formData, values[1].key) ?? '';

  const [stateData, setFieldState] = useState({
    primaryContactNumber: {
      key: values[0].key,
      value: primaryNumber
    },
    secondaryContactNumber: {
      key: values[1].key,
      value: secondaryNumber
    },
    countryCode: defaultCountryCode,
    options: getUiOptions(uiSchema.schema)
  });

  useEffect(() => {
    setFieldState({
      primaryContactNumber: {
        key: values[0].key,
        value: primaryNumber
      },
      secondaryContactNumber: {
        key: values[1].key,
        value: secondaryNumber
      },
      countryCode: defaultCountryCode,
      options: getUiOptions(props.uiSchema.schema)
    });
  }, [props.formData, props.uiSchema?.schema]);

  useEffect(() => {
    if (stateData) {
      const primaryContactNumber = objectPath.get(
        props.formData,
        values[0].key
      );
      const secondaryContactNumber = objectPath.get(
        props.formData,
        values[1].key
      );

      let newState = update(
        stateData,
        deepmerge.all([
          Utils.getPatch('primaryContactNumber.value', primaryContactNumber),
          Utils.getPatch('secondaryContactNumber.value', secondaryContactNumber)
        ])
      );
      setState(newState);
    }
  }, [props]);

  const setState = (options) => {
    setFieldState({
      ...stateData,
      ...options
    });
  };

  const handlePrimaryContactChange = (primaryPhone) => {
    if (!primaryPhone?.phone) return;

    const newPrimaryPhone = Utils.getPatch(
      'primaryContactNumber.value',
      primaryPhone?.phone
    );

    const newState = update(
      stateData,
      deepmerge.all([
        newPrimaryPhone,
        Utils.getPatch(
          'secondaryContactNumber.value',
          stateData.secondaryContactNumber.value
        )
      ])
    );

    setState(newState);

    const { onChange } = props;

    if (onChange)
      onChange(Utils.getPatch(primaryContactNumber.key, primaryPhone?.phone));
  };

  const handleSecondaryContactChange = (secondaryPhone) => {
    if (!secondaryPhone?.phone) return;

    const newSecondaryNumber = Utils.getPatch(
      'secondaryContactNumber.value',
      secondaryPhone?.phone
    );
    const newState = update(
      stateData,
      deepmerge.all([
        newSecondaryNumber,
        Utils.getPatch(
          'primaryContactNumber.value',
          stateData.primaryContactNumber.value
        )
      ])
    );

    setState(newState);

    const { onChange } = props;

    if (onChange)
      onChange(
        Utils.getPatch(secondaryContactNumber.key, secondaryPhone?.phone)
      );
  };

  const isValidNumber = (phoneNumber) => {
    if (
      phoneNumber.match(/12345/) ||
      phoneNumber.match(/123456789/) ||
      phoneNumber.match(/1234567890/) ||
      (phoneNumber.length > 3 && phoneNumber.length < 11)
    ) {
      return 'Invalid phone number';
    } else if (phoneNumber.startsWith('0')) {
      return 'Invalid country code';
    } else {
      return true;
    }
  };

  const { primaryContactNumber, secondaryContactNumber, countryCode, options } =
    stateData;
  const { pathReadonly } = Utils.resolvePathSettings(props);
  const readonly = pathReadonly || props.readonly || options?.readonly;

  const primaryNumberErrors = Utils.getErrorSchemaPath(
    props,
    primaryContactNumber.key
  ).__errors;
  const secondaryNumberErrors = Utils.getErrorSchemaPath(
    props,
    secondaryContactNumber.key
  ).__errors;

  return stateData ? (
    <div>
      <div
        className={
          'field field-string col-md-6' + (readonly ? ' readonly' : '')
        }
      >
        <label className='control-label'>
          {t(values[0].schema.label)}
          {values[0].schema.required ? <span className='req'> *</span> : null}
        </label>
        <PhoneInput
          id='primaryContactNumber'
          name='primaryContactNumber'
          country={countryCode}
          defaultCountry={countryCode}
          preferredCountries={['nz', 'au', 'us']}
          errorClass={styles.invalidNumberMessage}
          inputClass={styles.customPhoneInput}
          containerStyle={{ marginTop: '15px' }}
          searchClass='search-class'
          searchStyle={{ margin: '0', width: '97%', height: '30px' }}
          enableSearch
          value={primaryContactNumber.value}
          onChange={(phone) => handlePrimaryContactChange({ phone })}
          isValid={(value) => isValidNumber(value)}
        />
        {Utils.errorList(primaryNumberErrors)}
      </div>
      <div
        className={
          styles.phone + ' col-xs-6 field' + (readonly ? ' readonly' : '')
        }
      >
        <label className='control-label'>
          {t(values[1].schema.label)}
          {values[1].schema.required ? <span className='req'> *</span> : null}
        </label>
        <PhoneInput
          id='secondaryContactNumber'
          name='secondaryContactNumber'
          country={countryCode}
          defaultCountry={countryCode}
          preferredCountries={['nz', 'au', 'us']}
          errorClass={styles.invalidNumberMessage}
          inputClass={styles.customPhoneInput}
          containerStyle={{ marginTop: '15px' }}
          searchClass='search-class'
          searchStyle={{ margin: '0', width: '97%', height: '30px' }}
          enableSearch
          value={secondaryContactNumber.value}
          onChange={(phone) => handleSecondaryContactChange({ phone })}
          isValid={isValidNumber}
        />
        {Utils.errorList(secondaryNumberErrors)}
      </div>
    </div>
  ) : undefined;
}

PhoneField.propTypes = {
  environmentConfig: PropTypes.object,
  formData: PropTypes.object,
  includesHostName: PropTypes.string,
  onChange: PropTypes.func,
  readonly: PropTypes.bool,
  uiSchema: PropTypes.object
};
