import React, { useEffect, useState } from 'react';
import objectPath from 'object-path';
import update from 'immutability-helper';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Utils } from 'react-jsonschemapath-form';
import { getUiOptions } from 'react-jsonschema-form/lib/utils';
import styles from './CheckboxField.scss';

const CheckboxField = (props) => {
  const { t } = useTranslation();
  const [state, setFieldState] = useState({});

  useEffect(() => {
    handleDidMount();
  }, []);

  useEffect(() => {
    const { readonly } = Utils.resolvePathSettings(props);
    if (readonly && props.formData && state.key) {
      // Readonly so keep the state synced
      const value = objectPath.get(props.formData, state.key);
      updateValue(value);
    }
  }, [props]);

  const setState = (options) => {
    setFieldState({
      ...state,
      ...options
    });
  };

  const handleDidMount = () => {
    const key = props.uiSchema.key;
    const value = objectPath.get(props.formData, props.uiSchema.key) || false;
    const options = getUiOptions(props.uiSchema.schema);
    setState({
      key: key,
      value: value,
      options: options
    });
  };

  const onChange = () => {
    updateValue(!value);
  };

  const updateValue = (value) => {
    const newState = update(state, Utils.getPatch('value', value));
    setState(newState);
    props.onChange(Utils.getPatch(state.key, value));
  };

  const getDisabledTitle = (value, options, disabled, readonly, errors) => {
    if (options && options.disabledTitle && disabled && value) {
      return (
        <div className='row'>
          <div
            className={
              'field ' +
              'col-xs-12 ' +
              (errors ? 'has-error' : '') +
              ' ' +
              (readonly ? 'readonly' : '')
            }
          >
            <span className='checkbox-review-title'>
              {t(options.disabledTitle)}
            </span>
          </div>
        </div>
      );
    }
    return <span />;
  };

  const { pathDisabled, pathReadonly, pathAutoFocus } =
    Utils.resolvePathSettings(props);
  const { key, value, options } = state;

  const disabled = pathDisabled || props.disabled || options?.disabled;
  const readonly = pathReadonly || props.readonly || options?.readonly;
  const autofocus = pathAutoFocus || props.autofocus || options?.autofocus;

  const errors = Utils.getErrorSchemaPath(props, key).__errors;
  let checkInputClass = styles.checkbox;
  const inlineCheckbox = options?.inline ? options?.inline : false;
  if (inlineCheckbox) {
    checkInputClass = styles.checkboxInline;
  }

  const checkInput = (
    <input
      className={checkInputClass}
      type='checkbox'
      checked={value}
      onChange={onChange}
      disabled={disabled}
      readOnly={readonly}
      autoFocus={autofocus}
    />
  );

  return options?.disabledTitle && disabled ? (
    getDisabledTitle(value, options, disabled, readonly, errors)
  ) : (
    <div
      className='row'
      style={{ display: props.uiSchema['ui:hide'] ? 'none' : 'block' }}
    >
      <div
        className={
          'field ' +
          'col-xs-12 ' +
          (errors ? 'has-error' : '') +
          ' ' +
          (readonly ? 'readonly' : '')
        }
      >
        <span>
          <label className='control-label'>
            {options && options.inline ? checkInput : null}
            {options && options.customTitle ? (
              <span>{options.customTitle}</span>
            ) : (
              t(key)
            )}
            {options && options.key ? (
              Utils.isRequired(props, options.key) ? (
                <span className='req'> *</span>
              ) : null
            ) : null}
            {options && options.help ? (
              <OverlayTrigger
                placement='right'
                overlay={
                  <Tooltip id={'tooltip-' + key}>{t(options.help)}</Tooltip>
                }
              >
                <i className='glyphicon glyphicon-info-sign' />
              </OverlayTrigger>
            ) : null}
          </label>
          {options && !options.inline ? checkInput : null}
          {Utils.errorList(errors)}
        </span>
      </div>
    </div>
  );
};

export default CheckboxField;
