import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ScoreChart } from './ScoreChart';

export default function ScoreModal(props) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (props.visible) {
      setVisible(props.visible);
    }
  }, []);

  useEffect(() => {
    if (props.visible !== visible) {
      setVisible(props.visible);
    }
  }, [props.visible]);

  const onModalClose = () => {
    const { onHide } = props;
    if (onHide) {
      onHide();
    }
    setVisible(false);
  };

  const calculatePercentage = (val) => {
    if (val) {
      return (val * 100).toFixed() + '%';
    } else {
      return '0%';
    }
  };

  const { scorePercentage, jobWeightage, relatedObject } = props;
  return (
    <span>
      {scorePercentage ? (
        <Modal show={visible} onHide={onModalClose}>
          <Modal.Header closeButton className='brand-primary'>
            <Modal.Title>{props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row candidatescore'>
              <div className='col-lg-8 candidatescore-left'>
                <div className='table-responsive candidatescore-table'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th scope='col'>
                          {relatedObject?.candidate
                            ? relatedObject?.candidate?.firstName +
                              ' ' +
                              relatedObject?.candidate?.lastName
                            : props.heading}
                        </th>
                        <th scope='col'>
                          {'score ' + parseInt(props.value).toFixed() + '%'}
                        </th>
                        <th scope='col'></th>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th scope='col'></th>
                        <th scope='col'></th>
                        <th scope='col' style={{ fontSize: 10, color: '#000' }}>
                          {'Weighting applied'}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>JobTitle</td>
                        <td
                          style={{
                            backgroundColor: '#0088FE',
                            textAlign: 'center'
                          }}
                        >
                          {calculatePercentage(scorePercentage?.TitleScore)}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {calculatePercentage(jobWeightage?.job_title)}
                        </td>
                      </tr>
                      <tr>
                        <td>Skills</td>
                        <td
                          style={{
                            backgroundColor: '#00C49F',
                            textAlign: 'center'
                          }}
                        >
                          {calculatePercentage(scorePercentage?.SkillScore)}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {calculatePercentage(jobWeightage?.skill)}
                        </td>
                      </tr>
                      <tr>
                        <td>Notice Period</td>
                        <td
                          style={{
                            backgroundColor: '#FFBB28',
                            textAlign: 'center'
                          }}
                        >
                          {calculatePercentage(scorePercentage?.NoticePeriod)}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {calculatePercentage(jobWeightage?.notice)}
                        </td>
                      </tr>
                      <tr>
                        <td>Experience</td>
                        <td
                          style={{
                            backgroundColor: '#FF8042',
                            textAlign: 'center'
                          }}
                        >
                          {calculatePercentage(
                            scorePercentage?.ExperienceScore
                          )}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {calculatePercentage(jobWeightage?.experience)}
                        </td>
                      </tr>
                      <tr>
                        <td>Location</td>
                        <td
                          style={{
                            backgroundColor: '#319BC2',
                            textAlign: 'center'
                          }}
                        >
                          {calculatePercentage(scorePercentage?.LocationScore)}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {jobWeightage?.location}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='col-lg-4 candidatescore-right'>
                {scorePercentage ? (
                  <ScoreChart
                    scorepercentage={scorePercentage}
                    jobweightage={jobWeightage}
                  />
                ) : null}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </span>
  );
}
