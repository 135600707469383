import React, { useCallback, useEffect, useState } from 'react';
import { DropdownList } from 'react-widgets';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import deepmerge from 'deepmerge';
import styles from './SelectLocation.scss';
import { Utils } from 'react-jsonschemapath-form';
import { useTranslation } from 'react-i18next';

const SelectLocation = (props) => {
  const [stateData, setLocationState] = useState(props.initialData);
  const { t } = useTranslation();

  useEffect(() => {
    if (props?.formData?.jobRequisition?.jobRequisitionPublishedDetail?.city) {
      loadZipCodeData(
        props?.formData?.jobRequisition?.jobRequisitionPublishedDetail?.city
      );
    }
    if (props?.formData?.job?.publishedDetail?.city) {
      loadZipCodeData(props?.formData?.job?.publishedDetail?.city);
    }
  }, [props?.loadZipCodesByCountryData]);

  const setState = (options) => {
    setLocationState({
      ...stateData,
      ...options
    });
  };

  const loadZipCodeData = (cityData) => {
    let selectZipCode;
    const localZipCodes = loadZipCodesByCountryData[cityData];

    if (
      props.initialData?.zipCode &&
      localZipCodes &&
      localZipCodes?.findIndex(
        (z) => z.value === props.initialData?.zipCode.value
      ) >= 0
    ) {
      selectZipCode = props.initialData.zipCode;
    } else if (
      !isOversea &&
      localZipCodes?.length &&
      localZipCodes?.length === 1
    ) {
      selectZipCode = localZipCodes[0];
    }

    const newState = update(
      stateData,
      Utils.getPatch(
        'zipCode.value',
        selectZipCode ? selectZipCode.value : isOversea ? '' : null
      )
    );

    setState(newState);

    updateLocation(
      Utils.getPatch(
        zipCode.key,
        selectZipCode ? selectZipCode.value : isOversea ? '' : null
      )
    );
  };

  const getStateCode = (country, state) => {
    const { loadStateData } = props;
    if (loadStateData[country]) {
      const stateDetail = loadStateData[country].find((s) => s.value === state);
      return stateDetail ? stateDetail.id : null;
    }
  };

  const initialise = (countryValue) => {
    const defaultCountryValue =
      props.uiSchema.schema.default !== ''
        ? props.uiSchema.schema.default.split(',')
        : ['US'];
    const stateValue = null;
    const cityValue = null;
    const zipCodeValue = null;
    const stateCodeValue = null;
    const { stateCode, state, country, city, zipCode } = props.initialData;
    let newState = update(
      stateData,
      deepmerge.all([
        Utils.getPatch('country.value', countryValue),
        Utils.getPatch('state.value', stateValue),
        Utils.getPatch('stateCode.value', stateCodeValue),
        Utils.getPatch('city.value', cityValue),
        Utils.getPatch('zipCode.value', zipCodeValue)
      ])
    );
    const isOversea = !defaultCountryValue.includes(countryValue);

    newState = {
      ...newState,
      isOversea
    };

    setState(newState);
    updateLocation(
      deepmerge.all([
        Utils.getPatch(country.key, countryValue),
        Utils.getPatch(state.key, stateValue),
        Utils.getPatch(stateCode.key, stateCodeValue),
        Utils.getPatch(city.key, cityValue),
        Utils.getPatch(zipCode.key, zipCodeValue)
      ])
    );
  };

  const onChangeCountry = useCallback(
    (event) => {
      if (!event.id) {
        return;
      }

      const { getLoadStates, loadStateData } = props;

      if (!Object.prototype.hasOwnProperty.call(loadStateData, event.id)) {
        getLoadStates(event.id);
      }
      initialise(event.id);
    },
    [props.getLoadStates, props.loadStateData]
  );

  const onChangeState = useCallback(
    (event) => {
      if (!event.id) {
        return;
      }

      const { getLoadCitiesByCountryStateCode, loadCityData } = props;
      const { country } = stateData;

      if (!Object.prototype.hasOwnProperty.call(loadCityData, event.id)) {
        getLoadCitiesByCountryStateCode(country.value, event.id);
      }

      const { stateCode, state, city, zipCode } = props.initialData;
      const newState = update(
        stateData,
        deepmerge.all([
          Utils.getPatch('state.value', event.value),
          Utils.getPatch('stateCode.value', event.id),
          Utils.getPatch('city.value', null),
          Utils.getPatch('zipCode.value', null)
        ])
      );

      setState(newState);
      updateLocation(
        deepmerge.all([
          Utils.getPatch(state.key, event.value),
          Utils.getPatch(stateCode.key, event.id),
          Utils.getPatch(city.key, null),
          Utils.getPatch(zipCode.key, null)
        ])
      );
    },
    [
      props.getLoadCitiesByCountryStateCode,
      props.initialData,
      props.loadCityData
    ]
  );

  const onChangeCity = useCallback(
    (event) => {
      if (!event.id) {
        return;
      }

      const { getLoadZipCodesByCountry, loadZipCodesByCountryData } = props;
      const { isOversea, stateCode, country } = stateData;

      if (
        !isOversea &&
        !Object.prototype.hasOwnProperty.call(
          loadZipCodesByCountryData,
          event.id
        )
      ) {
        getLoadZipCodesByCountry(country.value, stateCode.value, event.id);
        updateCity(event);
      } else {
        updateCity(event);
      }
    },
    [
      props.getLoadZipCodesByCountry,
      props.loadZipCodesByCountryData,
      stateData.countries,
      stateData.isOversea,
      stateData.stateCode
    ]
  );

  const onChangeZipCode = useCallback(
    (event) => {
      if (!event.id) {
        return;
      }

      const { zipCode } = stateData;

      const newState = update(
        stateData,
        Utils.getPatch('zipCode.value', event.id) // TODO: remove hardcoding
      );

      setState(newState);
      updateLocation(Utils.getPatch(zipCode.key, event.id));
    },
    [stateData.zipCode]
  );

  const updateCity = (event) => {
    const { isOversea, city, zipCode } = stateData;

    let selectZipCode;

    const newState = update(
      stateData,
      deepmerge.all([
        Utils.getPatch('city.value', event.id),
        Utils.getPatch(
          'zipCode.value',
          selectZipCode ? selectZipCode.id : isOversea ? '' : null
        )
      ])
    );

    setState(newState);
    updateLocation(
      deepmerge.all([
        Utils.getPatch(city.key, event.value),
        Utils.getPatch(
          zipCode.key,
          selectZipCode ? selectZipCode.value : isOversea ? '' : null
        )
      ])
    );
  };

  const updateLocation = (patch) => {
    if (props.onChange) {
      props.onChange(patch);
    }
  };

  const { disabled, readonly } = Utils.resolvePathSettings(props);
  const { isOversea, country, city, zipCode, options, state } = stateData;
  const countryErrors = Utils.getErrorSchemaPath(
    props,
    stateData.country.key
  ).__errors;
  const stateErrors = Utils.getErrorSchemaPath(
    props,
    stateData.state.key
  ).__errors;
  const cityErrors = Utils.getErrorSchemaPath(
    props,
    stateData.city.key
  ).__errors;
  const zipCodeErrors = !isOversea
    ? Utils.getErrorSchemaPath(props, stateData.zipCode.key).__errors
    : null;
  const placeholder = t(props.placeholder || props.defaultPlaceholder);
  const {
    loadCountryData,
    loadStateData,
    loadCityData,
    loadZipCodesByCountryData
  } = props;

  return (
    <div className={styles.selectLocation}>
      {country.value !== '' && loadStateData[country.value] ? (
        <div>
          <div
            className={
              'col-xs-3 ' +
              (countryErrors ? 'field has-error' : '') +
              ' ' +
              (readonly ? 'readonly' : '')
            }
          >
            <label className='control-label'>
              {t('Locations.Country')}
              {Utils.isRequired(props, country.key) ? (
                <span className='req'> *</span>
              ) : null}
            </label>
            {readonly && !country.value ? null : (
              <DropdownList
                dataKey='id'
                textField='value'
                value={country.value}
                data={loadCountryData}
                onChange={(event) => onChangeCountry(event)}
                disabled={disabled}
                readOnly={readonly}
                placeholder={placeholder}
              />
            )}
            {Utils.errorList(countryErrors)}
          </div>
          <div
            className={
              'col-xs-3 ' +
              (stateErrors ? 'field has-error' : '') +
              ' ' +
              (readonly ? 'readonly' : '')
            }
          >
            <label className='control-label'>
              {t('Locations.State')}
              {Utils.isRequired(props, state.key) ? (
                <span className='req'> *</span>
              ) : null}
            </label>
            {readonly && !state.value ? null : (
              <DropdownList
                dataKey='id'
                textField='value'
                value={state.value}
                data={loadStateData[country.value]}
                onChange={(event) => onChangeState(event)}
                disabled={disabled}
                readOnly={readonly}
                placeholder={placeholder}
              />
            )}
            {Utils.errorList(stateErrors)}
          </div>
          <div
            className={
              'col-xs-3 ' +
              (cityErrors ? 'field has-error' : '') +
              ' ' +
              (readonly ? 'readonly' : '')
            }
          >
            <label className='control-label'>
              {t('Locations.City')}
              {Utils.isRequired(props, city.key) ? (
                <span className='req'> *</span>
              ) : null}
            </label>
            {readonly && !city.value ? null : (
              <DropdownList
                dataKey='id'
                textField='value'
                value={city.value}
                data={
                  loadCityData[state.value] ||
                  loadCityData[getStateCode(country.value, state.value)]
                }
                onChange={(event) => onChangeCity(event)}
                disabled={disabled}
                readOnly={readonly}
                placeholder={placeholder}
              />
            )}
            {Utils.errorList(cityErrors)}
          </div>
          {!isOversea ? (
            <div
              className={
                'col-xs-3 ' +
                (!isOversea && zipCodeErrors ? 'field has-error' : '') +
                ' ' +
                (readonly ? 'readonly' : '')
              }
            >
              <label className='control-label'>
                {t('Locations.ZipCode')}
                {!isOversea && Utils.isRequired(props, zipCode.key) ? (
                  <span className='req'> *</span>
                ) : null}
              </label>
              {readonly && !zipCode.value ? null : (
                <DropdownList
                  dataKey='id'
                  textField='value'
                  value={zipCode.value}
                  data={loadZipCodesByCountryData[city.value]}
                  onChange={(event) => onChangeZipCode(event)}
                  disabled={disabled}
                  readOnly={readonly}
                  placeholder={placeholder}
                />
              )}
              {Utils.errorList(zipCodeErrors)}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

SelectLocation.defaultProps = {
  defaultPlaceholder: 'Widget.SelectOption'
};

SelectLocation.propTypes = {
  countries: PropTypes.array,
  states: PropTypes.object,
  cities: PropTypes.object,
  zipCodes: PropTypes.object,
  defaultPlaceholder: PropTypes.string
};

export default SelectLocation;
